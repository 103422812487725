import { ReactNode, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import scss from "./styles.module.scss";
import CaretDown from "@icons/CaretDown";

interface Props {
  children: ReactNode;
  to: string;
  hovered?: boolean;
  showArrow?: boolean;
}
const MenuItem = ({ children, to, hovered, showArrow }: Props) => {
  const { pathname } = useLocation();
  const isActive = useMemo(() => pathname.startsWith(to), [to, pathname]);
  return (
    <NavLink className={classNames(scss.item, { [scss.itemActive]: isActive, [scss.itemHovered]: hovered })} to={to}>
      {children}
      {showArrow && <CaretDown className={classNames(scss.arrow, { [scss.activeArrow]: hovered })} />}
    </NavLink>
  );
};

export default MenuItem;
