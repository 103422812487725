import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const translateEN = require("./locales/translateEN.json");
const translateES = require("./locales/translateES.json");

const getLang = () => {
  const lang = localStorage.getItem("i18nextLng") || window.navigator.language || "en-US";
  if (lang?.startsWith("es")) return "es-ES";
  return "en-US";
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: translateEN
      },
      es: {
        translation: translateES
      }
    },

    fallbackLng: "en-US",
    lng: getLang(),
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"]
    }
  });

export default i18n;
