import scss from "./styles.module.scss";
import { ReactNode } from "react";
import cn from "classnames";

interface Props {
  children: ReactNode;
  classname?: string;
}

const MainLayout = ({ children, classname }: Props) => {
  return <div className={cn(scss.container, classname)}>{children}</div>;
};

export default MainLayout;
