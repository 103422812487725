import { Routes, Route, Navigate } from "react-router-dom";
import TradePage from "@components/Pages/TradePage/TradePage";
import BuyCryptoPage from "@components/Pages/BuyCryptoPage";
import LeaderboardPage from "@components/Pages/LeaderboardPage";
import MarketsPage from "@components/Pages/Markets";
import SignUp from "@components/Pages/Auth/SignUp/SignUp";
import LogIn from "@components/Pages/Auth/LogIn/LogIn";
import ForgotPassword from "@components/Pages/Auth/ForgotPassword/ForgotPassword";

const Router = () => {
  return (
    <Routes>
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/log-in" element={<LogIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/trade" element={<TradePage />} />
      <Route path="/buy-crypto" element={<BuyCryptoPage />} />
      <Route path="/markets" element={<MarketsPage />} />
      <Route path="/leaderboard" element={<LeaderboardPage />} />
      <Route path="*" element={<Navigate to="/trade" replace />} />
    </Routes>
  );
};

export default Router;
