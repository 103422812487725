import Globe from "@icons/Globe";
import Button from "@components/shared/Button";
import scss from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import ModalStore from "@store/ModalStore";
import LangModal from "./components/LangModal/LangModal";

const MobileLang = () => {
  const { i18n } = useTranslation();
  const label = i18n.language === "es-ES" ? "Español" : "English";
  return (
    <Button variant="secondary" size="large" fullWidth onClick={() => ModalStore.openModal(<LangModal />)}>
      <Globe />
      <div className={scss.label}>{label}</div>
    </Button>
  );
};

export default MobileLang;
