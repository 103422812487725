import React, { useEffect, useRef } from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import scss from "./styles.module.scss";
import cn from "classnames";
import Icon from "@components/shared/Icon";
import ArrowRight from "@icons/ArrowRight";
import DayInfoItem from "@components/Pages/TradePage/components/TickerHeader/components/LastDayInfo/DayInfoItem";

const throttle = require("lodash.throttle");

interface IProps {
  dayInfo: { key: string; value: string }[];
  classname?: string;
}

const LastDayInfo = ({ classname, dayInfo }: IProps) => {
  const menuRef = useRef<HTMLDivElement>(document.createElement("div"));
  const innerMenuRef = useRef<HTMLDivElement>(document.createElement("div"));
  const state = useLocalObservable(() => ({
    showMore: false,
    isStart: true,
    isEnd: true,
    setShowMore: (v: boolean) => (state.showMore = v),
    setStart: (v: boolean) => (state.isStart = v),
    setEnd: (v: boolean) => (state.isEnd = v)
  }));

  const setState = useRef(() => {
    if (menuRef.current) {
      runInAction(() => {
        state.setShowMore(menuRef.current.clientWidth < menuRef.current.scrollWidth);
        state.setStart(!menuRef.current.scrollLeft);
        state.setEnd(
          Math.round(menuRef.current.scrollLeft) === menuRef.current.scrollWidth - menuRef.current.clientWidth ||
            Math.round(menuRef.current.scrollLeft + 1) === menuRef.current.scrollWidth - menuRef.current.clientWidth ||
            Math.round(menuRef.current.scrollLeft - 1) === menuRef.current.scrollWidth - menuRef.current.clientWidth
        );
      });
    }
  });

  useEffect(() => {
    runInAction(() => {
      setState.current();
    });
    const handler = throttle(() => {
      setState.current();
    }, 150);

    const ro = new ResizeObserver(handler);
    if (menuRef.current) ro.observe(menuRef.current);
    if (innerMenuRef.current) ro.observe(innerMenuRef.current);

    menuRef.current && menuRef.current.addEventListener("scroll", handler);
    return () => {
      if (menuRef.current) ro.unobserve(menuRef.current);
      if (innerMenuRef.current) ro.unobserve(innerMenuRef.current);

      menuRef.current && menuRef.current.removeEventListener("scroll", handler);
    };
  }, [state]);

  const moveToStart = useRef(() => {
    if (menuRef.current) {
      menuRef.current.scrollBy({
        behavior: "smooth",
        left: -menuRef.current.clientWidth
      });
    }
  });

  const moveToEnd = useRef(() => {
    if (menuRef.current) {
      menuRef.current.scrollBy({
        behavior: "smooth",
        left: menuRef.current.clientWidth
      });
    }
  });
  return (
    <div className={cn(scss.container, classname)} ref={innerMenuRef}>
      <div className={cn(scss.itemsWrapper, { [scss.withPadding]: state.showMore })} ref={menuRef}>
        {dayInfo.map((item) => (
          <DayInfoItem key={item.value} itemKey={item.key} itemValue={item.value} />
        ))}
      </div>
      {state.showMore ? (
        <div
          className={cn(scss.actions, {
            [scss.actionsActive]: state.showMore
          })}
        >
          <Icon className={cn(scss.icon, scss.rotateIcon, { [scss.hide]: state.isStart })} onClick={moveToStart.current}>
            <ArrowRight width={20} height={20} />
          </Icon>
          <Icon className={cn(scss.icon, { [scss.hide]: state.isEnd })} onClick={moveToEnd.current}>
            <ArrowRight width={20} height={20} />
          </Icon>
        </div>
      ) : null}
    </div>
  );
};

export default observer(LastDayInfo);
