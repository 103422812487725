import React from "react";
import scss from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  classname?: string;
}

const History = ({ classname }: IProps) => {
  return <div className={cn(scss.container, classname)}>History</div>;
};

export default History;
