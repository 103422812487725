import { makeAutoObservable } from "mobx";

export type Resolution = { s: boolean; m: boolean; l: boolean; xl: boolean; xxl: boolean };

class ResolutionStore {
  _resolution: Resolution = { s: false, m: false, l: false, xl: false, xxl: false };

  constructor() {
    makeAutoObservable(this);
  }

  setResolution = (res: Partial<Resolution>) => {
    this._resolution.s = !!res.s;
    this._resolution.m = !!res.m;
    this._resolution.l = !!res.l;
    this._resolution.xl = !!res.xl;
    this._resolution.xxl = !!res.xxl;
  };

  get resolution() {
    return this._resolution;
  }

  get isMobile() {
    return this._resolution.s || this._resolution.m;
  }

  get isTablet() {
    return this._resolution.l;
  }

  get isTabletLarge() {
    return this._resolution.xl;
  }

  get isNotDesktop() {
    return this._resolution.xl || this._resolution.l || this._resolution.m || this._resolution.s;
  }

  get resolutionState() {
    return {
      isMobile: this.isMobile,
      isTablet: this.isTablet,
      isTabletLarge: this.isTabletLarge
    };
  }
}

export default new ResolutionStore();
