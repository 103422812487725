export type PopupPlacement = "top" | "top-start" | "top-end" | "bottom" | "bottom-start" | "bottom-end";

const getBetterPlacementY = (offset: DOMRect, popupOffset: DOMRect, currentPlacement: "bottom" | "top", indent: number = 0, topWindow: number = 0) => {
  const windowHeight = window.innerHeight;
  const _insideIndent = 20;
  const top = offset.top - indent;
  const bottom = windowHeight - (offset.bottom + indent);

  if (currentPlacement === "bottom") {
    if (popupOffset.height + _insideIndent < bottom) {
      return ["bottom", offset.bottom + indent];
    }
  } else {
    if (popupOffset.height + _insideIndent < top) {
      return ["top", top - popupOffset.height - indent];
    }
  }
  if (top > bottom) {
    if (popupOffset.height + _insideIndent < top) {
      return ["top", top - popupOffset.height - indent];
    } else {
      return ["top", _insideIndent, top - _insideIndent - topWindow];
    }
  }
  return ["bottom", offset.bottom + indent, bottom - _insideIndent];
};

const getBetterPlacementX: any = (offset: DOMRect, popupOffset: DOMRect, currentPlacement: "left" | "center" | "right", indent: number = 0) => {
  const windowWidth = document.body.clientWidth;
  const _insideIndent = indent;
  const left = offset.left;
  const right = windowWidth - (offset.left + offset.width + indent);

  if (currentPlacement === "right") {
    if (left + popupOffset.width + _insideIndent < windowWidth) {
      return ["right", left + _insideIndent];
    } else if (windowWidth - (popupOffset.width + _insideIndent) > 0) {
      return getBetterPlacementX(offset, popupOffset, "left", indent);
      // return ["right", windowWidth - (popupOffset.width + _insideIndent)];
    }
  } else if (currentPlacement === "left") {
    if (popupOffset.width + _insideIndent < offset.right) {
      return ["left", offset.right - popupOffset.width - indent];
    } else if (popupOffset.width + _insideIndent < windowWidth) {
      return ["left", _insideIndent, windowWidth];
    }
  } else {
    const halfPopup = popupOffset.width / 2;
    const halfTarget = offset.width / 2;
    const leftTarget = left + halfTarget;
    const rightTarget = right + halfTarget;

    if (halfPopup + _insideIndent < leftTarget && halfPopup + _insideIndent < rightTarget) {
      return ["center", leftTarget - halfPopup];
    }
    return getBetterPlacementX(offset, popupOffset, "left", indent);
  }

  if (right > left) {
    return ["right", left, windowWidth - left - _insideIndent];
  }
  return ["left", _insideIndent, popupOffset.width > windowWidth ? windowWidth : offset.right - _insideIndent];
};

export const getPopupPosition = (offset: DOMRect, popupOffset: DOMRect, indentY = 0, indentX = 0, placement: PopupPlacement = "bottom-start", topWindow: number = 0) => {
  let top;
  let left;
  let maxHeight;
  let maxWidth;

  // let betterPlacementY;
  // let betterPlacementX;

  const currentPlacementY = placement.includes("bottom") ? "bottom" : "top";
  const currentPlacementX = placement.includes("start") ? "right" : placement.includes("end") ? "left" : "center";
  [, top, maxHeight] = getBetterPlacementY(offset, popupOffset, currentPlacementY, indentY, topWindow);
  [, left, maxWidth] = getBetterPlacementX(offset, popupOffset, currentPlacementX, indentX);
  return [top, left, maxHeight, maxWidth];
};
