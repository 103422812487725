import scss from "./styles.module.scss";
import { ReactNode } from "react";
import classNames from "classnames";
import Icon from "@components/shared/Icon";
import CloseIcon from "@icons/CloseIcon";
import ModalStore from "@store/ModalStore";

interface Props {
  children: ReactNode;
  className?: string;
  size?: "medium";
}

export const ModalLayout = (props: Props) => {
  return <div className={classNames(scss.modal, scss[props.size || "medium"], props.className)}>{props.children}</div>;
};

interface HeaderProps {
  label?: ReactNode;
  className?: string;
}
export const ModalLayoutHeader = (props: HeaderProps) => {
  return (
    <div className={classNames(scss.header, props.className)}>
      <div className={scss.headerInner}>{props.label}</div>
      <Icon className={scss.headerIcon} onClick={ModalStore.closeModal}>
        <CloseIcon />
      </Icon>
    </div>
  );
};
