import { SVGProps } from "react";

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.5 12C12.5 12.1162 12.4552 12.2326 12.3657 12.3213L7.7824 16.8668C7.60331 17.0444 7.3133 17.0444 7.13432 16.8668C6.95534 16.6892 6.95523 16.4016 7.13432 16.2241L11.3936 12L7.13432 7.77589C6.95523 7.59828 6.95523 7.31067 7.13432 7.13317C7.31341 6.95567 7.60342 6.95555 7.7824 7.13317L12.3657 11.6786C12.4552 11.7674 12.5 11.8837 12.5 12Z"
      fill="currentColor"
    />
    <path
      d="M11.5 12C11.5 11.8838 11.5448 11.7674 11.6343 11.6787L16.2176 7.13321C16.3967 6.9556 16.6867 6.9556 16.8657 7.13321C17.0447 7.31082 17.0448 7.59844 16.8657 7.77594L12.6064 12L16.8657 16.2241C17.0448 16.4017 17.0448 16.6893 16.8657 16.8668C16.6866 17.0443 16.3966 17.0444 16.2176 16.8668L11.6343 12.3214C11.5448 12.2326 11.5 12.1163 11.5 12Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIcon;
