import { observer } from "mobx-react-lite";
import { useEffect, useRef, MouseEvent } from "react";
import { createPortal } from "react-dom";
import scss from "./styles.module.scss";
import RightMenuStore from "@store/RightMenuStore";
import classNames from "classnames";

const RightPanel = observer(() => {
  const state = RightMenuStore.state;
  const root = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    root.current.id = "right-panel";
    document.body.append(root.current);
  }, []);

  const onOutsideClick = (e: MouseEvent) => {
    if (e.currentTarget === e.target) RightMenuStore.closeMenu();
  };

  return createPortal(
    <div className={classNames(scss.overlay, { [scss.active]: state.opened })} onClick={onOutsideClick}>
      <div className={classNames(scss.panel, { [scss.active]: state.opened })}>{state.content}</div>
    </div>,
    root.current
  );
});

export default RightPanel;
