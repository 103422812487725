import MainLayout from "@components/Layouts/MainLayout";
import MainHeader from "@components/Layouts/MainHeader";

const MarketsPage = () => {
  return (
    <MainLayout>
      <MainHeader />
      <div style={{ color: "var(--color-white)" }}>Markets </div>
    </MainLayout>
  );
};

export default MarketsPage;
