import { observer } from "mobx-react-lite";
import ResolutionStore from "@store/ResolutionStore";
import scss from "./styles.module.scss";
import Logo from "@icons/Logo";
import HeaderMenu from "./components/HeaderMenu";
import HeaderAuth from "./components/HeaderAuth";
import HeaderLang from "./components/HeaderLang";
import HeaderSearch from "./components/HeaderSearch";
import BurgerMenu from "./components/HeaderMenu/components/BurgerMenu";

const MainHeader = observer(() => {
  const { isMobile, isTablet, isTabletLarge } = ResolutionStore.resolutionState;

  return (
    <div className={scss.header}>
      <div className={scss.inner}>
        <Logo className={scss.logo} />
        {!isMobile && <HeaderMenu />}
        {!isTablet && !isMobile && <HeaderSearch />}
        <div className={scss.spacer} />
        {!isTabletLarge && !isTablet && !isMobile ? (
          <>
            <HeaderAuth />
            <HeaderLang />
          </>
        ) : (
          <BurgerMenu />
        )}
      </div>
    </div>
  );
});

export default MainHeader;
