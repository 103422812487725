import { MutableRefObject, useCallback, useEffect } from "react";
import * as popupPosition from "../utils/popupPosition";
import scss from "../styles.module.scss";
import useRefData from "@hooks/useRefData";
import { PopupPlacement } from "../utils/popupPosition";
import popupStore from "@store/PopupStore";

interface IProps {
  root: MutableRefObject<HTMLDivElement | undefined>;
  popup: MutableRefObject<HTMLDivElement | undefined>;
  target: EventTarget | undefined;
  open?: boolean;
  placement?: PopupPlacement;
  indentX?: number;
  indentY?: number;
  showClassName?: string;
}

const usePopupPosition = ({ popup, indentX, indentY, open, placement, showClassName, target, root }: IProps) => {
  const _target = useRefData<HTMLElement>(target as HTMLElement);

  const _open = useRefData(open);

  const setPosition = useCallback(() => {
    requestAnimationFrame(() => {
      if (_open.current && open) {
        const _targetOffset = _target.current.getBoundingClientRect();
        if (popup.current) {
          const _popupOffset = popup.current && popup.current.getBoundingClientRect();
          const [top, left, maxHeight, maxWidth] = popupPosition.getPopupPosition(_targetOffset, _popupOffset, indentY, indentX, placement);
          popup.current.style.top = `${top}px`;
          popup.current.style.left = `${left}px`;
          popup.current.style.maxHeight = maxHeight ? `${maxHeight}px` : "unset";
          popup.current.style.maxWidth = maxWidth ? `${maxWidth}px` : "unset";
          popup.current.classList.add(showClassName || scss.show);
        }
      }
    });
  }, [placement, indentX, indentY, open, _open, _target, popup, showClassName]);

  useEffect(() => {
    const rootCurrent = root.current;
    const popupCurrent = popup.current;
    if (open && popupCurrent) {
      popupStore.ready();
      rootCurrent?.appendChild(popupCurrent);
      setPosition();
    }

    return () => {
      if (open && popupCurrent && rootCurrent?.contains(popupCurrent)) {
        rootCurrent.removeChild(popupCurrent);
        popupCurrent.classList.remove(showClassName || scss.show);
      }
    };
  }, [open, placement, indentX, indentY, setPosition, popup, root, showClassName]);

  return setPosition;
};

export default usePopupPosition;
