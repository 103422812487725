import Icon from "@components/shared/Icon";
import BurgerIcon from "@icons/BurgerIcon";
import scss from "./styles.module.scss";
import { useCallback, useEffect } from "react";
import RightMenuStore from "@store/RightMenuStore";
import MobilePanel from "./components/MobilePanel/MobilePanel";

const BurgerMenu = () => {
  useEffect(() => {
    return () => RightMenuStore.closeMenu();
  }, []);

  const openMenu = useCallback(() => {
    RightMenuStore.openMenu(<MobilePanel />);
  }, []);

  return (
    <div>
      <Icon className={scss.icon} onClick={openMenu}>
        <BurgerIcon />
      </Icon>
    </div>
  );
};

export default BurgerMenu;
