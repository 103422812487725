import { MenuItem } from "@components/shared/Menu";
import Tabs from "@components/shared/Tabs";
import BitcoinIcon from "@icons/crypto/BitcoinIcon";
import MenuLayout, { MenuLayoutItem } from "@components/Layouts/MainHeader/components/HeaderMenu/components/MenuLayout";
import EthereumIcon from "@icons/crypto/EthereumIcon";
import CardanoIcon from "@icons/crypto/CardanoIcon";
import RippleIcon from "@icons/crypto/RippleIcon";
import LitecoinIcon from "@icons/crypto/LitecoinIcon";
import SolanaIcon from "@icons/crypto/SolanaIcon";

const items: MenuLayoutItem[] = [
  {
    title: "Spot Trading",
    description: "Buy & Sell on the Spot",
    SubMenu: () => (
      <Tabs
        tabs={[
          {
            label: "USDT",
            content: () => (
              <div>
                <MenuItem title="BTC" caption="/USDT" description="Bitcoin" iconBefore={<BitcoinIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="ETH" caption="/USDT" description="Ethereum" iconBefore={<EthereumIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="ADA" caption="/USDT" description="Cardano" iconBefore={<CardanoIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="XRP" caption="/USDT" description="Ripple" iconBefore={<RippleIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="LTC" caption="/USDT" description="Litecoin" iconBefore={<LitecoinIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="SOL" caption="/USDT" description="Solana" iconBefore={<SolanaIcon />} arrowIcon displayArrowIconByHover />
              </div>
            )
          },
          {
            label: "BTC",
            content: () => (
              <div>
                <MenuItem title="ETH" caption="/BTC" description="Ethereum" iconBefore={<EthereumIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="ADA" caption="/BTC" description="Cardano" iconBefore={<CardanoIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="SOL" caption="/BTC" description="Solana" iconBefore={<SolanaIcon />} arrowIcon displayArrowIconByHover />
              </div>
            )
          },
          {
            label: "USDC",
            content: () => (
              <div>
                <MenuItem title="BTC" caption="/USDC" description="Bitcoin" iconBefore={<BitcoinIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="ETH" caption="/USDC" description="Ethereum" iconBefore={<EthereumIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="ADA" caption="/USDC" description="Cardano" iconBefore={<CardanoIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="XRP" caption="/USDC" description="Ripple" iconBefore={<RippleIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="LTC" caption="/USDC" description="Litecoin" iconBefore={<LitecoinIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="SOL" caption="/USDC" description="Solana" iconBefore={<SolanaIcon />} arrowIcon displayArrowIconByHover />
              </div>
            )
          },
          {
            label: "DAI",
            content: () => (
              <div>
                <MenuItem title="BTC" caption="/DAI" description="Bitcoin" iconBefore={<BitcoinIcon />} arrowIcon displayArrowIconByHover />
                <MenuItem title="ETH" caption="/DAI" description="Ethereum" iconBefore={<EthereumIcon />} arrowIcon displayArrowIconByHover />
              </div>
            )
          }
        ]}
      />
    )
  },
  {
    title: "Derivatives Trading",
    description: "Trade contracts with leverage"
  }
];

const TradingMenu = () => {
  return <MenuLayout items={items} />;
};

export default TradingMenu;
