import scss from "./styles.module.scss";
import authScss from "../styles.module.scss";
import MainLayout from "@components/Layouts/MainLayout";
import MainHeader from "@components/Layouts/MainHeader";
import AdvertisingImage from "@images/auth-advertising.png";
import Button from "@components/shared/Button";
import Tabs from "@components/shared/Tabs";
import Input from "@components/shared/Input/Input";
import NumberInput from "@components/shared/Input/NumberInput";
import { useTranslation } from "react-i18next";
import ContinueWithApple from "@icons/ContinueWithApple";
import ContinueWithGoogle from "@icons/ContinueWithGoogle";
import classNames from "classnames";
import Telegram from "@icons/Telegram";
import ArrowBrandRight from "@icons/ArrowBrandRight";

const LogIn = () => {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <MainHeader />
      <div className={authScss.whitePage}>
        <div className={authScss.container}>
          <div className={authScss.col}>
            <div className={authScss.paper}>
              <h3 className={authScss.h3}>{t("login")}</h3>
              <div className={authScss.flexRow}>
                <p className={authScss.question}>{t("newToCooplix")}</p>
                <Button type="link" to="/sign-up" className={authScss.authButton}>
                  {t("signup")}
                </Button>
              </div>

              <Tabs
                noScroll
                variant="light"
                tabs={[
                  {
                    label: t("email"),
                    content: () => (
                      <div className={authScss.inputWrapper}>
                        <Input label={t("email")} type="email" placeholder={t("emailPlaceholder")} />
                        <Input label="Password" type="password" placeholder="••••••••" validate={(value) => value.length === 8} />
                      </div>
                    )
                  },
                  {
                    label: "Mobile Number",
                    content: () => <NumberInput label={t("mobile")} />
                  }
                ]}
              />

              <Button type="link" to="/forgot-password" variant="secondary" className={scss.forgotPassword}>
                {t("forgotPassword")}
              </Button>

              <Button size="xl">{t("login")}</Button>

              <div className={authScss.divider}>
                <p>{t("or")}</p>
              </div>

              <Button size="xl" className={classNames(authScss.authButton, authScss.continueWithButton)} iconBefore={<ContinueWithGoogle />}>
                {t("continueWithGoogle")}
              </Button>
              <Button size="xl" className={classNames(authScss.authButton, authScss.continueWithButton)} iconBefore={<ContinueWithApple />}>
                {t("continueWithApple")}
              </Button>
            </div>
          </div>

          <div className={authScss.col}>
            <div className={authScss.paper}>
              <h3 className={authScss.advertizing}>{t("authAdvertising")}</h3>
              <p className={authScss.subtitle}>{t("authAdvertisingSubtitle")}</p>
              <img src={AdvertisingImage} alt={t("authAdvertising")} />
            </div>

            <div className={classNames(authScss.paper, scss.joinTG)}>
              <Telegram className={scss.joinTGIcon} />
              <div className={scss.flexCol}>
                <h4 className={scss.joinTGHeader}>{t("joinCooplixTG")}</h4>
                <p className={scss.joinTGSubtitle}>{t("joinCooplixTG")}</p>
              </div>
              <Button type="link" to="#" variant="custom" className={scss.arrowLink}><ArrowBrandRight /></Button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default LogIn;
