import { memo, SVGProps } from "react";

const Copy = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.5 7.44984C17.4913 7.37328 17.4746 7.29786 17.45 7.22484V7.14984C17.4099 7.06415 17.3565 6.98539 17.2917 6.9165L12.2917 1.9165C12.2228 1.85168 12.144 1.79824 12.0583 1.75817C12.0335 1.75464 12.0082 1.75464 11.9833 1.75817C11.8987 1.70962 11.8052 1.67846 11.7083 1.6665H8.33333C7.67029 1.6665 7.03441 1.9299 6.56557 2.39874C6.09673 2.86758 5.83333 3.50346 5.83333 4.1665V4.99984H5C4.33696 4.99984 3.70107 5.26323 3.23223 5.73207C2.76339 6.20091 2.5 6.8368 2.5 7.49984V15.8332C2.5 16.4962 2.76339 17.1321 3.23223 17.6009C3.70107 18.0698 4.33696 18.3332 5 18.3332H11.6667C12.3297 18.3332 12.9656 18.0698 13.4344 17.6009C13.9033 17.1321 14.1667 16.4962 14.1667 15.8332V14.9998H15C15.663 14.9998 16.2989 14.7364 16.7678 14.2676C17.2366 13.7988 17.5 13.1629 17.5 12.4998V7.49984C17.5 7.49984 17.5 7.49984 17.5 7.44984ZM12.5 4.50817L14.6583 6.6665H13.3333C13.1123 6.6665 12.9004 6.57871 12.7441 6.42243C12.5878 6.26615 12.5 6.05418 12.5 5.83317V4.50817ZM12.5 15.8332C12.5 16.0542 12.4122 16.2661 12.2559 16.4224C12.0996 16.5787 11.8877 16.6665 11.6667 16.6665H5C4.77899 16.6665 4.56702 16.5787 4.41074 16.4224C4.25446 16.2661 4.16667 16.0542 4.16667 15.8332V7.49984C4.16667 7.27882 4.25446 7.06686 4.41074 6.91058C4.56702 6.7543 4.77899 6.6665 5 6.6665H5.83333V12.4998C5.83333 13.1629 6.09673 13.7988 6.56557 14.2676C7.03441 14.7364 7.67029 14.9998 8.33333 14.9998H12.5V15.8332ZM15.8333 12.4998C15.8333 12.7209 15.7455 12.9328 15.5893 13.0891C15.433 13.2454 15.221 13.3332 15 13.3332H8.33333C8.11232 13.3332 7.90036 13.2454 7.74408 13.0891C7.5878 12.9328 7.5 12.7209 7.5 12.4998V4.1665C7.5 3.94549 7.5878 3.73353 7.74408 3.57725C7.90036 3.42097 8.11232 3.33317 8.33333 3.33317H10.8333V5.83317C10.8333 6.49621 11.0967 7.1321 11.5656 7.60094C12.0344 8.06978 12.6703 8.33317 13.3333 8.33317H15.8333V12.4998Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Copy);
