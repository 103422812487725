import React from "react";
import ReactSlider from "react-slider";
import scss from "./styles.module.scss";
import SliderTrack from "@components/Pages/TradePage/components/Spot/components/SpotSlider/components/SliderTrack";
import SliderMark from "@components/Pages/TradePage/components/Spot/components/SpotSlider/components/SliderMark";
import SliderThumb from "@components/Pages/TradePage/components/Spot/components/SpotSlider/components/SliderThumb";
import cn from "classnames";

interface IProps {
  min?: number;
  max?: number;
  defaultStartValue?: number;
  marks?: number[];
  sliderValue: number;
  setSliderValue: (value: number) => void;
  classNames?: {
    container?: string;
    slider?: string;
    filledTrack?: string;
    unfilledTrack?: string;
    thumb?: string;
    mark?: string;
    filledMark?: string;
  };
}

const Slider = ({ min = 0, max = 100, marks = [min, max], defaultStartValue = min, sliderValue, setSliderValue, classNames }: IProps) => {
  return (
    <div className={cn(scss.container, classNames?.container)}>
      <ReactSlider
        min={min}
        max={max}
        marks={marks}
        className={cn(scss.sliderWrapper, classNames?.slider)}
        onChange={(value) => setSliderValue(value)}
        defaultValue={defaultStartValue}
        renderTrack={(props, state) => (
          <SliderTrack
            key={state.index}
            state={state}
            props={props}
            classNames={{
              filledTrack: classNames?.filledTrack,
              unfilledTrack: classNames?.unfilledTrack
            }}
          />
        )}
        renderThumb={(props) => <SliderThumb props={props} />}
        renderMark={(props) => <SliderMark key={props.key} props={props} onClick={setSliderValue} value={sliderValue} />}
      />
    </div>
  );
};

export default Slider;
