import { SVGProps } from "react";
const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="40" height="10" viewBox="0 0 40 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.48">
      <path d="M0 9.83379V0.166205H2.42245V7.96399H6.66175V9.83379H0Z" fill="white" />
      <path
        d="M12.2754 10C11.5423 10 10.8729 9.89381 10.2673 9.68144C9.66168 9.46907 9.14637 9.15512 8.72138 8.73961C8.29639 8.31487 7.96702 7.79317 7.73328 7.17452C7.49953 6.54663 7.38266 5.82179 7.38266 5C7.38266 4.17821 7.49953 3.45799 7.73328 2.83934C7.96702 2.21145 8.29639 1.68975 8.72138 1.27424C9.14637 0.849492 9.66168 0.530933 10.2673 0.31856C10.8729 0.106187 11.5423 0 12.2754 0C13.0085 0 13.6779 0.106187 14.2835 0.31856C14.8891 0.530933 15.4044 0.849492 15.8294 1.27424C16.2544 1.68975 16.5837 2.21145 16.8175 2.83934C17.0512 3.45799 17.1681 4.17821 17.1681 5C17.1681 5.82179 17.0512 6.54663 16.8175 7.17452C16.5837 7.79317 16.2544 8.31487 15.8294 8.73961C15.4044 9.15512 14.8891 9.46907 14.2835 9.68144C13.6779 9.89381 13.0085 10 12.2754 10ZM12.2754 8.13019C13.0085 8.13019 13.5769 7.92244 13.9807 7.50693C14.395 7.09141 14.6022 6.49584 14.6022 5.72022V4.27978C14.6022 3.50416 14.395 2.90859 13.9807 2.49307C13.5769 2.07756 13.0085 1.86981 12.2754 1.86981C11.5423 1.86981 10.9685 2.07756 10.5542 2.49307C10.1504 2.90859 9.94855 3.50416 9.94855 4.27978V5.72022C9.94855 6.49584 10.1504 7.09141 10.5542 7.50693C10.9685 7.92244 11.5423 8.13019 12.2754 8.13019Z"
        fill="white"
      />
      <path
        d="M26.2502 8.2964H26.1705C26.0855 8.78578 25.7986 9.19206 25.3099 9.51523C24.8211 9.83841 24.1571 10 23.3177 10C22.6803 10 22.0853 9.89381 21.5328 9.68144C20.9803 9.46907 20.4969 9.15512 20.0825 8.73961C19.6681 8.3241 19.3441 7.80702 19.1103 7.18837C18.8766 6.56971 18.7597 5.85411 18.7597 5.04155C18.7597 4.22899 18.8819 3.50877 19.1263 2.88089C19.3706 2.253 19.7106 1.72668 20.1462 1.30194C20.5925 0.877193 21.1237 0.554016 21.74 0.33241C22.3562 0.110803 23.0415 0 23.7959 0C24.7946 0 25.6552 0.189289 26.3777 0.567867C27.1002 0.937212 27.6686 1.49585 28.083 2.24377L26.1068 3.22715C25.9368 2.84857 25.6711 2.53001 25.3099 2.27147C24.9486 2.00369 24.444 1.86981 23.7959 1.86981C23.0415 1.86981 22.4412 2.05909 21.995 2.43767C21.5487 2.81625 21.3256 3.41182 21.3256 4.22438V5.77562C21.3256 6.57895 21.5487 7.17452 21.995 7.56233C22.4412 7.94091 23.0415 8.13019 23.7959 8.13019C24.0933 8.13019 24.3749 8.10249 24.6405 8.04709C24.9061 7.98246 25.1399 7.89012 25.3418 7.77008C25.5436 7.64081 25.6977 7.48846 25.8039 7.31302C25.9208 7.13758 25.9793 6.92982 25.9793 6.68975V6.20499H23.9074V4.44598H28.2901V9.83379H26.2502V8.2964Z"
        fill="white"
      />
      <path
        d="M35.1073 10C34.3742 10 33.7048 9.89381 33.0992 9.68144C32.4936 9.46907 31.9783 9.15512 31.5533 8.73961C31.1283 8.31487 30.7989 7.79317 30.5652 7.17452C30.3314 6.54663 30.2146 5.82179 30.2146 5C30.2146 4.17821 30.3314 3.45799 30.5652 2.83934C30.7989 2.21145 31.1283 1.68975 31.5533 1.27424C31.9783 0.849492 32.4936 0.530933 33.0992 0.31856C33.7048 0.106187 34.3742 0 35.1073 0C35.8404 0 36.5098 0.106187 37.1154 0.31856C37.721 0.530933 38.2363 0.849492 38.6613 1.27424C39.0863 1.68975 39.4156 2.21145 39.6494 2.83934C39.8831 3.45799 40 4.17821 40 5C40 5.82179 39.8831 6.54663 39.6494 7.17452C39.4156 7.79317 39.0863 8.31487 38.6613 8.73961C38.2363 9.15512 37.721 9.46907 37.1154 9.68144C36.5098 9.89381 35.8404 10 35.1073 10ZM35.1073 8.13019C35.8404 8.13019 36.4088 7.92244 36.8126 7.50693C37.2269 7.09141 37.4341 6.49584 37.4341 5.72022V4.27978C37.4341 3.50416 37.2269 2.90859 36.8126 2.49307C36.4088 2.07756 35.8404 1.86981 35.1073 1.86981C34.3742 1.86981 33.8004 2.07756 33.3861 2.49307C32.9823 2.90859 32.7805 3.50416 32.7805 4.27978V5.72022C32.7805 6.49584 32.9823 7.09141 33.3861 7.50693C33.8004 7.92244 34.3742 8.13019 35.1073 8.13019Z"
        fill="white"
      />
    </g>
  </svg>
);

export default Logo;
