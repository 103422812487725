import React from "react";
import CaretDown from "@icons/CaretDown";
import scss from "./styles.module.scss";
import { SpotTabs } from "@components/Pages/TradePage/components/Spot/Spot";
import cn from "classnames";

interface IProps {
  isActive: boolean;
  changeTabHandler: (tab: SpotTabs) => void;
  title: SpotTabs;
  withDropdown?: boolean;
}

const SpotTabItem = ({ isActive, changeTabHandler, title, withDropdown }: IProps) => {
  const changeSpotTab = () => {
    if (!isActive) changeTabHandler(title);
  };

  return (
    <div className={cn(scss.tabItem, { [scss.active]: isActive })} onClick={changeSpotTab}>
      {title}
      {withDropdown ? <CaretDown /> : null}
    </div>
  );
};

export default SpotTabItem;
