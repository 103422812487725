import { SVGProps } from "react";

const RippleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="12" fill="#171C21" />
    <path
      d="M16.9494 6H18.9743L14.7615 10.3153C13.2366 11.8774 10.7641 11.8774 9.23913 10.3153L5.02637 6H7.05124L10.2516 9.27822C11.2174 10.2675 12.7832 10.2675 13.7491 9.27822L16.9494 6Z"
      fill="white"
    />
    <path
      d="M7.02488 17.9998H5L9.23879 13.6579C10.7638 12.0958 13.2362 12.0958 14.7612 13.6579L19 17.9998H16.9751L13.7487 14.6949C12.7829 13.7056 11.217 13.7056 10.2512 14.6949L7.02488 17.9998Z"
      fill="white"
    />
  </svg>
);

export default RippleIcon;
