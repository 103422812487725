import { Dispatch, FC, memo, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import useRefData from "@hooks/useRefData";
import MenuContent from "@components/Layouts/MainHeader/components/MenuPopup/MenuContent";
import scss from "./styles.module.scss";

interface Props {
  containerClassName?: string;
  className?: string;
  children: (({ opened, setOpened }: { opened: boolean; setOpened: Dispatch<SetStateAction<boolean>> }) => ReactNode) | ReactNode;
  position?: "left" | "right";
  behavior?: "hover" | "click";
  Content: FC<{ closeMenu: () => void }>;
}
const MenuPopup = ({ behavior, position, Content, containerClassName, ...props }: Props) => {
  const [opened, setOpened] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const openedRef = useRefData(opened);
  const hovered = opened && behavior === "hover";

  const targetProps = useMemo(() => {
    return behavior === "hover"
      ? {
          onMouseMove: () => {
            if (!openedRef.current) setOpened(true);
          }
        }
      : {
          onClick: () => {
            setOpened(true);
          }
        };
  }, [behavior, setOpened, openedRef]);

  const onOutsideClick = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  useEffect(() => {
    if (behavior === "hover" && opened) {
      const onMouseHandler = (event: MouseEvent) => {
        if (overlayRef.current === (event.target as any)) {
          setOpened(false);
        }
      };

      document.addEventListener("mousemove", onMouseHandler, true);
      return () => {
        document.removeEventListener("mousemove", onMouseHandler, true);
      };
    }
  }, [opened, behavior]);

  return (
    <>
      {hovered && <div ref={overlayRef} className={scss.hoveLayer} />}
      <div className={cn(containerClassName, { [scss.hovered]: hovered })}>
        <div ref={ref} className={cn(props.className)} {...targetProps}>
          {typeof props.children === "function" ? props.children({ opened, setOpened }) : props.children}
        </div>
        {opened && (
          <MenuContent targetRef={ref} behavior={behavior} position={position} onOutsideClick={onOutsideClick}>
            <Content closeMenu={onOutsideClick} />
          </MenuContent>
        )}
      </div>
    </>
  );
};

MenuPopup.defaultProps = {
  position: "right",
  behavior: "click"
};

export default memo(MenuPopup);
