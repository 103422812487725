import { memo, MouseEvent, ReactNode, RefObject, useEffect, useRef } from "react";
import classNames from "classnames";
import scss from "./styles.module.scss";
type Position = "left" | "right";

interface Props {
  children: ReactNode;
  targetRef: RefObject<HTMLDivElement>;
  position?: Position;
  onOutsideClick: () => void;
  behavior?: "hover" | "click";
}

const MenuContent = ({ children, targetRef, position, onOutsideClick, behavior }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const targetOffset = targetRef.current?.getBoundingClientRect();
    const contentOffset = ref.current?.getBoundingClientRect();
    if (!targetOffset || !contentOffset || !ref.current) return;
    const { clientHeight } = document.body;

    const [, left, maxWidth] = getHorizontalPosition(targetOffset, contentOffset, position as Position);
    ref.current.style.transform = `translate3d(${left}px, ${targetOffset.bottom}px, 0px)`;
    ref.current.style.maxHeight = `${clientHeight - targetOffset.bottom - 10}px`;
    ref.current.style.maxWidth = maxWidth ? `${maxWidth}px` : "unset";
    ref.current.classList.add(scss.show);
  }, [position, targetRef]);

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) onOutsideClick();
  };

  return (
    <div className={classNames(scss.overlay, { [scss.hiddenOverlay]: behavior === "hover" })} onClick={onClick}>
      <div ref={ref} className={classNames(scss.menu)}>
        {children}
      </div>
    </div>
  );
};

const getHorizontalPosition = (targetOffset: DOMRect, contentOffset: DOMRect, position: Position, indent: number = 0): any => {
  const windowWidth = document.body.clientWidth;
  const left = targetOffset.left;
  const right = windowWidth - (targetOffset.left + targetOffset.width + indent);
  if (position === "right") {
    if (left + contentOffset.width + indent < windowWidth) {
      return ["right", left + indent];
    } else if (windowWidth - (contentOffset.width + indent) > 0) {
      return getHorizontalPosition(targetOffset, contentOffset, "left", indent);
    }
  } else if (position === "left") {
    if (contentOffset.width + indent < targetOffset.right) {
      return ["left", targetOffset.right - contentOffset.width - indent];
    } else if (contentOffset.width + indent < windowWidth) {
      return ["left", indent, windowWidth];
    }
  } else {
    const halfPopup = contentOffset.width / 2;
    const halfTarget = targetOffset.width / 2;
    const leftTarget = left + halfTarget;
    const rightTarget = right + halfTarget;

    if (halfPopup + indent < leftTarget && halfPopup + indent < rightTarget) {
      return ["center", leftTarget - halfPopup];
    }
    return getHorizontalPosition(targetOffset, contentOffset, "left", indent);
  }

  if (right > left) {
    return ["right", left, windowWidth - left - indent];
  }
  return ["left", indent, targetOffset.width > windowWidth ? windowWidth : targetOffset.right - indent];
};

export default memo(MenuContent);
