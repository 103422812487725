import Button from "@components/shared/Button";
import scss from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface Props {
  className?: string;
}
const MobileAuth = ({ className }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(scss.auth, className)}>
      <Button variant="secondary" size="large" fullWidth className={scss.login}>
        {t("login")}
      </Button>
      <Button size="large" fullWidth>
        {t("signup")}
      </Button>
    </div>
  );
};

export default MobileAuth;
