import { ReactNode, useCallback, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import scss from "./styles.module.scss";
import CaretDown from "@icons/CaretDown";
import Icon from "@components/shared/Icon";

interface Props {
  children?: ReactNode;
  to: string;
  label: string;
}
const MobileMenuItem = ({ children, to, label }: Props) => {
  const [opened, setOpened] = useState(false);
  const { pathname } = useLocation();
  const isActive = useMemo(() => pathname.startsWith(to), [to, pathname]);
  const onClick = useCallback(() => {
    setOpened((s) => !s);
  }, [setOpened]);
  return (
    <div className={classNames(scss.item, { [scss.opened]: opened })}>
      <div className={scss.row}>
        <NavLink className={classNames(scss.link, { [scss.linkActive]: isActive })} to={to}>
          {label}
        </NavLink>
        {!!children && (
          <Icon onClick={onClick} className={scss.arrow}>
            <CaretDown className={scss.arrowIcon} />
          </Icon>
        )}
      </div>
      {children && <div className={scss.subMenu}>{children}</div>}
    </div>
  );
};

export default MobileMenuItem;
