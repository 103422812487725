import { SVGProps } from "react";

const ContinueWithGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="24" height="24" rx="12" fill="white" />
    <path
      d="M19.8299 12.18C19.8299 11.6533 19.7833 11.1533 19.7033 10.6666H12.1699V13.6733H16.4833C16.2899 14.66 15.7233 15.4933 14.8833 16.06V18.06H17.4566C18.9633 16.6666 19.8299 14.6133 19.8299 12.18Z"
      fill="#4285F4"
    />
    <path
      d="M12.17 19.9999C14.33 19.9999 16.1367 19.2799 17.4567 18.0599L14.8834 16.0599C14.1634 16.5399 13.25 16.8333 12.17 16.8333C10.0834 16.8333 8.3167 15.4266 7.68336 13.5266H5.03003V15.5866C6.34336 18.1999 9.04336 19.9999 12.17 19.9999Z"
      fill="#34A853"
    />
    <path
      d="M7.68326 13.5267C7.51659 13.0467 7.42992 12.5333 7.42992 12C7.42992 11.4667 7.52326 10.9533 7.68326 10.4733V8.41333H5.02992C4.48325 9.49333 4.16992 10.7067 4.16992 12C4.16992 13.2933 4.48325 14.5067 5.02992 15.5867L7.68326 13.5267Z"
      fill="#FBBC05"
    />
    <path
      d="M12.17 7.16667C13.35 7.16667 14.4034 7.57334 15.2367 8.36667L17.5167 6.08667C16.1367 4.79333 14.33 4 12.17 4C9.04336 4 6.34336 5.8 5.03003 8.41334L7.68336 10.4733C8.3167 8.57334 10.0834 7.16667 12.17 7.16667Z"
      fill="#EA4335"
    />
  </svg>
);

export default ContinueWithGoogle;
