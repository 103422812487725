import MainLayout from "@components/Layouts/MainLayout";
import MainHeader from "@components/Layouts/MainHeader";

const BuyCryptoPage = () => {
  return (
    <MainLayout>
      <MainHeader />
      <div style={{ color: "var(--color-white)" }}>Buy Crypto </div>
    </MainLayout>
  );
};

export default BuyCryptoPage;
