import scss from "./styles.module.scss";
import authScss from "../styles.module.scss";
import MainLayout from "@components/Layouts/MainLayout";
import MainHeader from "@components/Layouts/MainHeader";
import Button from "@components/shared/Button";
import Tabs from "@components/shared/Tabs";
import Input from "@components/shared/Input/Input";
import NumberInput from "@components/shared/Input/NumberInput";
import { useTranslation } from "react-i18next";
import Warning from "@icons/Warning";

const ForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <MainHeader />
      <div className={authScss.whitePage}>
        <div className={authScss.container}>
          <div className={authScss.col}>
            <div className={authScss.paper}>
              <h3 className={authScss.h3}>{t("resetPassword")}</h3>

              <Tabs
                noScroll
                variant="light"
                tabs={[
                  {
                    label: t("email"),
                    content: () => (
                      <div className={authScss.inputWrapper}>
                        <div className={scss.warning}>
                          <Warning className={scss.warningIcon} />
                          {t("withdrawalRestricted")}
                        </div>
                        <Input label={t("email")} type="email" placeholder={t("emailPlaceholder")} />
                      </div>
                    )
                  },
                  {
                    label: t("mobile"),
                    content: () => <NumberInput label={t("mobile")} />
                  }
                ]}
              />

              <Button className={scss.next} size="xl">{t("next")}</Button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ForgotPassword;
