import { SVGProps } from "react";

const BurgerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3332 16.0002C26.3332 15.4479 25.8855 15.0002 25.3332 15.0002H6.6665C6.11422 15.0002 5.6665 15.4479 5.6665 16.0002C5.6665 16.5524 6.11422 17.0002 6.6665 17.0002H25.3332C25.8855 17.0002 26.3332 16.5524 26.3332 16.0002Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3332 9.3335C26.3332 8.78121 25.8855 8.3335 25.3332 8.3335H6.6665C6.11422 8.3335 5.6665 8.78121 5.6665 9.3335C5.6665 9.88578 6.11422 10.3335 6.6665 10.3335H25.3332C25.8855 10.3335 26.3332 9.88578 26.3332 9.3335Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3332 22.6668C26.3332 22.1145 25.8855 21.6668 25.3332 21.6668H6.6665C6.11422 21.6668 5.6665 22.1145 5.6665 22.6668C5.6665 23.2191 6.11422 23.6668 6.6665 23.6668H25.3332C25.8855 23.6668 26.3332 23.2191 26.3332 22.6668Z"
      fill="currentColor"
    />
  </svg>
);

export default BurgerIcon;
