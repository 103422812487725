import React from "react";
import scss from "./styles.module.scss";
import Button from "@components/shared/Button";
import Icon from "@components/shared/Icon";
import ThreeDotsVertical from "@icons/ThreeDotsVertical";
import GearIcon from "@icons/GearIcon";

const SpotHeader = () => {
  return (
    <div className={scss.spotHeader}>
      <div className={scss.containerTitle}>Spot</div>
      <Button className={scss.marginButton} size={"small"} variant={"primary"}>
        Enable Margin
      </Button>
      <Icon className={scss.headerIcon}>
        <ThreeDotsVertical />
      </Icon>
      <Icon className={scss.headerIcon}>
        <GearIcon />
      </Icon>
    </div>
  );
};

export default SpotHeader;
