import scss from "./styles.module.scss";
import { FC, useState } from "react";
import classNames from "classnames";

type Tab = {
  label: string;
  content: FC<any>;
};

interface Props {
  tabs: Tab[];
  variant: "dark" | "light";
  noScroll?: boolean;
}

const Tabs = ({ tabs, variant, noScroll }: Props) => {
  const [index, setIndex] = useState(0);
  const selectedTab = tabs[index];

  return (
    <div className={classNames(scss.container, scss[variant], {[scss.noScroll]: noScroll})}>
      <div className={scss.header}>
        {tabs.map(({ label }, i) => (
          <div key={label} className={classNames(scss.tab, { [scss.active]: index === i })} onClick={() => setIndex(i)}>
            {label}
          </div>
        ))}
      </div>
      <div className={scss.content}>
        <div className={scss.sectionScroll}>
          <selectedTab.content />
        </div>
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  variant: "dark"
};

export default Tabs;
