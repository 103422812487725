import { useEffect } from "react";
import throttle from "lodash.throttle";
import ResolutionStore from "@store/ResolutionStore";

const ResolutionDetector = () => {
  useEffect(() => {
    const handler = () => {
      const width = window.innerWidth;
      const res = {
        s: false,
        m: false,
        l: false,
        xl: false,
        xxl: false
      };
      if (width < 360) res.s = true;
      else if (width < 568) res.m = true;
      else if (width < 768) res.l = true;
      else if (width < 1024) res.xl = true;
      else if (width < 1440) res.xxl = true;
      ResolutionStore.setResolution(res);
    };
    handler();
    const handlerWithThrottle = throttle(handler, 100);
    window.addEventListener("resize", handlerWithThrottle, true);
    return () => {
      window.removeEventListener("resize", handlerWithThrottle, true);
    };
  }, []);
  return null;
};

// const checkResolution = (width: number) => window.matchMedia(`(max-width: ${width}px), (max-device-width: ${width}px)`).matches;

export default ResolutionDetector;
