import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import scss from "./styles.module.scss";
import classNames from "classnames";

interface Props extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: ReactNode;
}

const Icon = ({ children, className, ...props }: Props) => {
  return (
    <button className={classNames(scss.icon, className)} {...props}>
      {children}
    </button>
  );
};

export default Icon;
