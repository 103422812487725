import { ReactNode, useId, useState } from "react";
import classNames from "classnames";
import scss from "./styles.module.scss";

interface Props {
  label?: string | ReactNode ;
  onChange: (value: boolean) => void;
  className?: string;
  defaultValue: boolean;
}

const Checkbox = ({ label, onChange, defaultValue, className }: Props) => {
  const [value, setValue] = useState<boolean>(defaultValue);
  const id = useId();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
    onChange(e.target.checked);
  };

  return (
    <div className={classNames(scss.container, className)}>
      <input id={id} className={scss.input} type="checkbox" checked={value} onChange={handleChange} />

      <label htmlFor={id} className={scss.label}>
        {label}
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  onChange: () => {},
  defaultValue: false
};

export default Checkbox;
