import { SVGProps } from "react";

const BitcoinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.6408 14.9029C22.0379 21.3315 15.5261 25.2438 9.09602 23.6407C2.66858 22.038 -1.24421 15.5266 0.359461 9.09838C1.96163 2.66907 8.47345 -1.24364 14.9016 0.359095C21.3313 1.96183 25.2437 8.47401 23.6408 14.9029Z"
      fill="#F7931A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4686 10.4323C17.7183 8.75108 16.4475 7.8473 14.7099 7.24438L15.2736 4.96696L13.8973 4.62147L13.3486 6.83888C12.9868 6.74807 12.6152 6.66239 12.2459 6.5775L12.7986 4.34548L11.4232 4L10.8591 6.27663C10.5596 6.20793 10.2657 6.14002 9.9803 6.06855L9.98187 6.06145L8.08391 5.58409L7.71781 7.06473C7.71781 7.06473 8.73891 7.30045 8.71735 7.31506C9.27474 7.45522 9.37548 7.82677 9.35863 8.12131L8.71657 10.7158C8.75498 10.7257 8.80476 10.7399 8.85964 10.762L8.813 10.7503L8.8129 10.7502C8.78102 10.7422 8.74796 10.7338 8.71421 10.7257L7.81423 14.3601C7.74603 14.5307 7.57317 14.7866 7.18354 14.6894C7.19726 14.7096 6.18322 14.4379 6.18322 14.4379L5.5 16.0248L7.29094 16.4745C7.49093 16.525 7.68852 16.5769 7.88404 16.6282L7.88406 16.6282C8.01428 16.6624 8.14358 16.6964 8.27206 16.7296L7.70252 19.033L9.07719 19.3785L9.64124 17.0995C10.0168 17.2022 10.3813 17.2969 10.738 17.3862L10.1759 19.6545L11.5521 20L12.1217 17.7009C14.4684 18.1482 16.2331 17.9678 16.9759 15.8297C17.5745 14.1082 16.9461 13.1152 15.7114 12.4677C16.6106 12.2588 17.2879 11.663 17.4686 10.4323ZM14.3242 14.8738C13.934 16.4532 11.4797 15.8003 10.3568 15.5015L10.3566 15.5015C10.2557 15.4746 10.1655 15.4506 10.0885 15.4313L10.8442 12.3796C10.938 12.4032 11.0525 12.4291 11.1823 12.4584L11.1823 12.4584C12.3438 12.721 14.724 13.2589 14.3242 14.8738ZM11.4157 11.0422C12.3519 11.2939 14.3942 11.8429 14.7499 10.4074C15.1131 8.93914 13.1283 8.49654 12.1589 8.28038C12.0499 8.25606 11.9537 8.23461 11.8751 8.21489L11.1899 10.9827C11.2547 10.999 11.3306 11.0194 11.4157 11.0422Z"
      fill="white"
    />
  </svg>
);

export default BitcoinIcon;
