import Icon from "@components/shared/Icon";
import Globe from "@icons/Globe";
import scss from "./styles.module.scss";
import MenuPopup from "@components/Layouts/MainHeader/components/MenuPopup";
import HeaderLangMenu from "@components/Layouts/MainHeader/components/HeaderLang/HeaderLangMenu";
import classNames from "classnames";

interface Props {
  className?: string;
}
const HeaderLang = ({ className }: Props) => {
  return (
    <MenuPopup className={classNames(scss.container, className)} position="left" Content={HeaderLangMenu}>
      <Icon className={scss.button}>
        <Globe />
      </Icon>
    </MenuPopup>
  );
};

export default HeaderLang;
