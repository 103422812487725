import { BrowserRouter } from "react-router-dom";
import Router from "@routes/Router";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import ResolutionDetector from "@components/ResolutionDetector";
import RightPanel from "@components/RightPanel/RightPanel";
import Modal from "@components/Modal/Modal";
import Popup from "@components/Popup/Popup";

function App() {
  return (
    <>
      <ResolutionDetector />
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Router />
          <RightPanel />
          <Popup />
          <Modal />
        </I18nextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
