import scss from "./styles.module.scss";

import { useTranslation } from "react-i18next";
import MobileMenuItem from "./components/MobileMenuItem/MobileMenuItem";

const MobileMenu = () => {
  const { t } = useTranslation();
  return (
    <div className={scss.menu}>
      <div>
        <MobileMenuItem to="/buy-crypto" label={t("byCrypto")} />
        <MobileMenuItem to="/markets" label={t("markets")} />
        <MobileMenuItem to="/trade" label={t("trade")}>
          <MobileMenuItem to="/trade1" label="Spot Trading" />
          <MobileMenuItem to="/trade1" label="Derevatives" />
        </MobileMenuItem>
        <MobileMenuItem to="/leaderboard" label={t("leaderboard")} />
      </div>
    </div>
  );
};

export default MobileMenu;
