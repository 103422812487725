import { MouseEventHandler, ReactNode } from "react";
import scss from "./styles.module.scss";
import classNames from "classnames";
import ArrowRight from "@icons/ArrowRight";

interface Props {
  title: ReactNode;
  description?: ReactNode;
  caption?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  active?: boolean;
  arrowIcon?: boolean;
  displayArrowIconByHover?: boolean;
  iconBefore?: ReactNode;
  index?: number;
  large?: boolean;
}
const MenuItem = ({ title, description, large, active, onClick, arrowIcon, index, iconBefore, caption, displayArrowIconByHover }: Props) => {
  const hasSubtitle = !!description;
  return (
    <div
      data-index={index}
      className={classNames(scss.item, { [scss.large]: large || hasSubtitle, [scss.active]: active, [scss.hoverIcon]: displayArrowIconByHover })}
      onClick={onClick}
    >
      {iconBefore}
      <div className={scss.content}>
        <div className={scss.itemTitle}>
          {title} {caption && <div className={scss.caption}>{caption}</div>}
        </div>
        {hasSubtitle && <div className={scss.subTitle}>{description}</div>}
      </div>
      {arrowIcon && <ArrowRight className={scss.arrow} />}
    </div>
  );
};

export default MenuItem;
