import scss from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import MenuPopup from "@components/Layouts/MainHeader/components/MenuPopup";
import TradingMenu from "@components/Layouts/MainHeader/components/HeaderMenu/components/TradingMenu";
import MenuItem from "@components/Layouts/MainHeader/components/HeaderMenu/components/MenuItem";

const HeaderMenu = () => {
  const { t } = useTranslation();
  return (
    <div className={scss.menu}>
      <div className={scss.inner}>
        <MenuItem to="/buy-crypto">{t("byCrypto")}</MenuItem>
        <MenuItem to="/markets">{t("markets")}</MenuItem>
        <MenuPopup behavior="hover" position="right" Content={TradingMenu}>
          {({ opened }) => (
            <MenuItem to="/trade" hovered={opened} showArrow>
              {t("trade")}
            </MenuItem>
          )}
        </MenuPopup>
        <MenuItem to="/leaderboard">{t("leaderboard")}</MenuItem>
      </div>
    </div>
  );
};

export default HeaderMenu;
