import scss from "./styles.module.scss";
import { useRef, useMemo, useState } from "react";
import { getCountryCallingCode, Country } from "react-phone-number-input/input";
import PopupStore from "@store/PopupStore";
import PhonePopup from "../PhoneCountryPopup/PhonePopup";
import classNames from "classnames";

export type CountryCode = {
  code: string;
  name: string;
  key: Country;
};

type Option = {
  value: Country;
  label: string;
};

type Props = {
  onChange: (value: string) => void;
  options: Option[];
  iconComponent: React.ElementType<{ country: string; label: string }>;
  value: Country;
};

const CustomPhoneCountrySelect = ({ onChange, options, value: selectedCountryCode, iconComponent: Icon }: Props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const _phoneCountryCodeRef = useRef<HTMLDivElement>(null);

  const codes: CountryCode[] = useMemo(() => {
    return options.map((option: Option) => ({
      code: getCountryCallingCode(option.value),
      name: option.label,
      key: option.value
    }));
  }, [options]);

  const handleChangeCountryCode = (value: Country) => {
    onChange(value);
    setIsFocused(false); // Finish when local popup will be completed 

    PopupStore.closePopup();
  };

  const handleOpenPhonePopup = () => {
    if (_phoneCountryCodeRef.current === null) {
      return;
    }

    setIsFocused(true);

    PopupStore.openPopup(_phoneCountryCodeRef.current, <PhonePopup codes={codes} iconComponent={Icon} onChange={handleChangeCountryCode} />);
  };

  return (
    <div ref={_phoneCountryCodeRef} className={classNames(scss.phoneCountryCodeContainer, { [scss.focused]: isFocused })} onClick={handleOpenPhonePopup}>
      <div className={scss.phoneCountryFlag}>
        <Icon country={selectedCountryCode} label={selectedCountryCode} />
      </div>
      <div className={scss.phoneCountryCode}>+{getCountryCallingCode(selectedCountryCode)}</div>
      <div className={scss.phoneCaret}></div>
    </div>
  );
};

export default CustomPhoneCountrySelect;
