import { Menu } from "@components/shared/Menu";
import MenuItem from "../../../../shared/Menu/MenuItem";
import { useTranslation } from "react-i18next";

interface Props {
  closeMenu: () => void;
}
const HeaderLangMenu = ({ closeMenu }: Props) => {
  const { i18n } = useTranslation();
  return (
    <Menu enableTopBorderRadius={false}>
      <MenuItem
        title="English"
        active={i18n.language === "en-US"}
        onClick={() => {
          closeMenu();
          i18n.changeLanguage("en-US");
        }}
      />
      <MenuItem
        title="Español"
        active={i18n.language === "es-ES"}
        onClick={() => {
          closeMenu();
          i18n.changeLanguage("es-ES");
        }}
      />
    </Menu>
  );
};

export default HeaderLangMenu;
