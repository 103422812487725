import { ReactNode, useMemo, useState } from "react";
import scss from "./styles.module.scss";
import EyeOpen from "@icons/EyeOpen";
import EyeClosed from "@icons/EyeClosed";
import classNames from "classnames";

type Props = {
  type: "text" | "password" | "email";
  iconBefore?: ReactNode;
  callOnChangeEachSymbol: boolean;
  label?: string | ReactNode;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  validate: (value: string) => boolean;
};

const Input = ({ type, label, placeholder, iconBefore, validate, onChange, value: propsValue, callOnChangeEachSymbol }: Props) => {
  const [value, setValue] = useState<string>(propsValue);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isShowPassword, setShowPassword] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (callOnChangeEachSymbol) {
      onChange(e.target.value);
    }

    setValue(e.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (validate(value)) {
      onChange(value);
      setErrorMessage("");
    } else {
      setErrorMessage("Something wrong.");
    }
  };

  const inputType = useMemo(() => {
    if (type === "password") {
      return isShowPassword ? "text" : "password";
    }

    return type;
  }, [type, isShowPassword]);

  return (
    <div className={classNames(scss.container, scss[type], { [scss.error]: errorMessage, [scss.focus]: isFocused })}>
      <label className={scss.label}>{label}</label>
      <div className={scss.inputWrapper}>
        {iconBefore && <span className={scss.icon}>{iconBefore}</span>}

        <input className={scss.input} type={inputType} placeholder={placeholder} value={value} onChange={handleChange} onBlur={handleBlur} onFocus={handleFocus} />
     
        {type === "password" && (
          <span className={scss.showPassword} onMouseOver={() => setShowPassword(true)} onMouseOut={() => setShowPassword(false)}>
            {isShowPassword ? <EyeOpen /> : <EyeClosed />}
          </span>
        )}
      </div>
      <span className={scss.errorMessage}>{errorMessage}</span>
    </div>
  );
};

Input.defaultProps = {
  type: "text",
  value: "",
  validate: () => true,
  onChange: () => {},
  callOnChangeEachSymbol: false
};

export default Input;
