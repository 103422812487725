import React, { useRef } from "react";
import scss from "./styles.module.scss";
import { ICurrencyInfo } from "@components/Pages/TradePage/components/TickerHeader/TickerHeader";
import cn from "classnames";
import ArrowsIcon from "@icons/ArrowsIcon";
import Icon from "@components/shared/Icon";
import resolutionStore from "@store/ResolutionStore";
import { observer } from "mobx-react-lite";
import popupStore from "@store/PopupStore";
import TestPopup from "@components/Popup/TestPopup";

interface IProps {
  currencyInfo: ICurrencyInfo;
  classname?: string;
  changeCurrencyHandler: () => void;
}

const CurrencyInfo = ({ currencyInfo, classname, changeCurrencyHandler }: IProps) => {
  const isNotDesktop = resolutionStore.isNotDesktop;
  const ref = useRef<HTMLDivElement>(document.createElement("div"));
  const clickHandler = () => {
    popupStore.openPopup(ref.current, <TestPopup />);
  };
  return (
    <div className={cn(scss.container, classname)}>
      <div ref={ref}>
        <Icon onClick={clickHandler} className={scss.icon}>
          <ArrowsIcon />
        </Icon>
      </div>
      <div className={scss.currencyIconWrapper}>{currencyInfo.cryptoLogo}</div>
      <div className={scss.infoWrapper}>
        <div className={scss.subWrapper}>
          <div className={scss.cryptoShortTitle}>{currencyInfo.cryptoCurrency}</div>
          <div className={scss.monetaryTitle}>{`/${currencyInfo.monetaryCurrency}`}</div>
          <div className={scss.fees}>{`${isNotDesktop ? "0" : "ZERO"} FEES`}</div>
        </div>
        <div className={scss.subWrapper}>
          <div className={scss.cryptoTitle}>{currencyInfo.cryptoTitle}</div>
          <div className={scss.fees}>5X</div>
        </div>
      </div>
    </div>
  );
};

export default observer(CurrencyInfo);
