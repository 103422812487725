import React from "react";
import scss from "./styles.module.scss";

interface IProps {
  itemKey: string;
  itemValue: string;
}

const DayInfoItem = ({ itemValue, itemKey }: IProps) => {
  return (
    <div className={scss.itemWrapper}>
      <div className={scss.itemKey}>{itemKey}</div>
      <div className={scss.itemValue}>{itemValue}</div>
    </div>
  );
};

export default DayInfoItem;
