import { SVGProps } from "react";

const CardanoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="12" fill="#0033AD" />
    <path
      d="M8.38429 11.9437C8.35014 12.5472 8.80431 13.0646 9.39908 13.0999H9.45794C10.0447 13.0992 10.523 12.6223 10.5338 12.0271C10.5446 11.4319 10.0839 10.9374 9.49758 10.9149C8.91128 10.8923 8.41501 11.35 8.38135 11.9443L8.38429 11.9437Z"
      fill="white"
    />
    <path
      d="M4.36733 11.6666C4.17557 11.6557 4.01139 11.8045 4.00056 11.9991C3.98973 12.1936 4.13635 12.3603 4.3281 12.3714C4.51984 12.3824 4.68417 12.2338 4.69519 12.0392C4.70065 11.9457 4.66913 11.8537 4.60759 11.7838C4.54606 11.7138 4.45959 11.6717 4.36733 11.6666Z"
      fill="white"
    />
    <path
      d="M8.31583 5.60075C8.48659 5.51178 8.55427 5.29946 8.46721 5.12589C8.38015 4.95232 8.17113 4.88287 7.99974 4.97056C7.82835 5.05825 7.75913 5.27006 7.84493 5.44428C7.88666 5.52864 7.95986 5.59262 8.04827 5.622C8.13668 5.65137 8.233 5.64373 8.31583 5.60075Z"
      fill="white"
    />
    <path
      d="M9.40884 7.53431C9.67409 7.39856 9.78068 7.07037 9.64695 6.80123C9.51321 6.53208 9.18977 6.42385 8.92447 6.55947C8.65916 6.69509 8.55241 7.02322 8.68601 7.29243C8.75021 7.4218 8.86246 7.51997 8.99803 7.56534C9.1336 7.6107 9.28139 7.59954 9.40884 7.53431Z"
      fill="white"
    />
    <path
      d="M5.73051 8.86452C5.88035 8.96449 6.07479 8.96301 6.22312 8.86076C6.37146 8.75852 6.44445 8.57566 6.40805 8.3975C6.37165 8.21935 6.23303 8.081 6.05686 8.047C5.88069 8.01301 5.70169 8.09006 5.60337 8.24223C5.46929 8.44973 5.52619 8.7282 5.73051 8.86452Z"
      fill="white"
    />
    <path
      d="M6.38571 11.4677C6.08913 11.4507 5.8351 11.6808 5.81826 11.9817C5.80142 12.2826 6.02814 12.5404 6.32472 12.5576C6.62129 12.5748 6.87547 12.3449 6.89252 12.044C6.90925 11.7429 6.68244 11.485 6.38571 11.4677Z"
      fill="white"
    />
    <path
      d="M5.7893 15.1377C5.57087 15.2497 5.48326 15.5201 5.59359 15.7418C5.70391 15.9634 5.97042 16.0524 6.18892 15.9405C6.40741 15.8287 6.49519 15.5583 6.38499 15.3366C6.33203 15.23 6.2395 15.1492 6.12777 15.1119C6.01604 15.0746 5.89428 15.0839 5.7893 15.1377Z"
      fill="white"
    />
    <path
      d="M7.88066 10.3393C8.17321 10.5338 8.56578 10.4509 8.75753 10.1541C8.94928 9.85734 8.8676 9.45903 8.57509 9.26445C8.28258 9.06988 7.88999 9.15271 7.69819 9.44947C7.6059 9.59197 7.57328 9.76586 7.60751 9.9328C7.64174 10.0997 7.74002 10.246 7.88066 10.3393Z"
      fill="white"
    />
    <path
      d="M14.5161 7.49151C14.7651 7.65635 15.0988 7.58517 15.2613 7.33251C15.4239 7.07984 15.3538 6.74132 15.1048 6.57632C14.8559 6.41131 14.5222 6.48228 14.3595 6.73484C14.2811 6.85618 14.2536 7.00417 14.283 7.14615C14.3123 7.28812 14.3962 7.41238 14.5161 7.49151Z"
      fill="white"
    />
    <path
      d="M15.6199 5.56332C15.7378 5.64173 15.8906 5.64034 16.0071 5.55978C16.1237 5.47923 16.1808 5.33539 16.152 5.19537C16.1232 5.05535 16.0141 4.94674 15.8755 4.92021C15.737 4.89368 15.5964 4.95446 15.5192 5.0742C15.414 5.23749 15.4591 5.45637 15.6199 5.56332Z"
      fill="white"
    />
    <path
      d="M14.6007 10.9032C14.0066 10.8686 13.4974 11.3291 13.4632 11.9319C13.4291 12.5347 13.883 13.0514 14.4771 13.086C14.4977 13.086 14.5189 13.086 14.5395 13.086C14.9641 13.0858 15.3489 12.8326 15.5223 12.4394C15.6957 12.0462 15.6252 11.5864 15.3424 11.2651C15.153 11.0484 14.8858 10.918 14.6007 10.9032Z"
      fill="white"
    />
    <path
      d="M9.76256 10.2634C9.94497 10.6331 10.318 10.8661 10.7256 10.8648C11.1001 10.8649 11.4478 10.6677 11.6439 10.344C11.8401 10.0203 11.8565 9.61643 11.6874 9.2774C11.5037 8.9094 11.1309 8.67822 10.7244 8.68019C10.351 8.68083 10.0046 8.87746 9.80872 9.19992C9.61283 9.52238 9.59536 9.92472 9.76256 10.2634Z"
      fill="white"
    />
    <path
      d="M18.2094 8.863C18.4279 8.75114 18.5157 8.48082 18.4055 8.25911C18.2954 8.03741 18.029 7.9482 17.8104 8.05984C17.5919 8.17148 17.5038 8.44171 17.6137 8.66353C17.724 8.88538 17.9906 8.97465 18.2094 8.863Z"
      fill="white"
    />
    <path
      d="M15.4775 9.21146C15.1651 9.3711 15.0394 9.75747 15.1967 10.0744C15.3541 10.3914 15.7349 10.5189 16.0473 10.3593C16.3597 10.1997 16.4854 9.8133 16.3281 9.49634C16.2526 9.34401 16.1206 9.22838 15.961 9.17494C15.8015 9.1215 15.6275 9.13464 15.4775 9.21146Z"
      fill="white"
    />
    <path
      d="M11.9591 5.39869C12.1381 5.40904 12.3056 5.30894 12.3835 5.14508C12.4614 4.98122 12.4343 4.78589 12.3149 4.65019C12.1954 4.5145 12.0072 4.46517 11.838 4.52523C11.6687 4.58528 11.5518 4.74287 11.5417 4.92451C11.528 5.17234 11.7148 5.38458 11.9591 5.39869Z"
      fill="white"
    />
    <path
      d="M11.9543 8.22904C12.3035 8.2489 12.6024 7.97793 12.6221 7.62371C12.6419 7.26948 12.3749 6.96606 12.0258 6.94588C11.6767 6.9257 11.3775 7.1964 11.3575 7.5506C11.3377 7.90514 11.6049 8.20883 11.9543 8.22904Z"
      fill="white"
    />
    <path
      d="M8.5225 14.7887C8.75127 14.6717 8.88802 14.4264 8.86898 14.1671C8.84994 13.9079 8.67887 13.6859 8.43553 13.6046C8.1922 13.5232 7.92455 13.5986 7.75739 13.7956C7.59024 13.9926 7.55651 14.2724 7.67194 14.5044C7.8295 14.8212 8.21025 14.9484 8.5225 14.7887Z"
      fill="white"
    />
    <path
      d="M12.3632 9.16675C12.093 9.58518 12.1373 10.1364 12.4707 10.5049C12.8042 10.8734 13.3417 10.9651 13.7756 10.7276C14.2096 10.4901 14.4292 9.98396 14.3088 9.49884C14.1884 9.01372 13.7587 8.67349 13.2656 8.67285C12.902 8.6726 12.5627 8.85829 12.3632 9.16675Z"
      fill="white"
    />
    <path
      d="M14.2364 13.7372C13.9975 13.2567 13.4494 13.0241 12.9445 13.1889C12.4396 13.3538 12.1281 13.8671 12.2104 14.3988C12.2926 14.9304 12.7441 15.3222 13.2745 15.3222C13.4431 15.321 13.6092 15.2801 13.7596 15.2028C14.2891 14.9308 14.5023 14.2756 14.2364 13.7372Z"
      fill="white"
    />
    <path
      d="M16.1187 13.6607C15.8263 13.4659 15.4336 13.5485 15.2417 13.8452C15.0497 14.1419 15.1311 14.5403 15.4235 14.735C15.716 14.9298 16.1086 14.8472 16.3006 14.5505C16.4919 14.2537 16.4105 13.8558 16.1187 13.6607Z"
      fill="white"
    />
    <path
      d="M18.1811 12.019C18.1979 11.7181 17.9711 11.4604 17.6745 11.4433C17.3779 11.4262 17.1238 11.6562 17.1068 11.9571C17.0899 12.258 17.3165 12.5159 17.6131 12.5332C17.9098 12.5502 18.1641 12.3201 18.1811 12.019Z"
      fill="white"
    />
    <path
      d="M19.6717 11.63C19.531 11.6218 19.3993 11.7004 19.3379 11.8291C19.2766 11.9579 19.2978 12.1114 19.3915 12.2182C19.4853 12.3249 19.6332 12.3639 19.7663 12.3168C19.8994 12.2698 19.9915 12.146 19.9996 12.0032C20.0103 11.8084 19.8637 11.6415 19.6717 11.63Z"
      fill="white"
    />
    <path
      d="M18.2682 15.1356C18.0634 14.9995 17.7888 15.0576 17.6546 15.2653C17.5204 15.473 17.5776 15.7517 17.7823 15.8878C17.9869 16.024 18.2616 15.9661 18.3959 15.7585C18.4604 15.6587 18.4832 15.537 18.4593 15.4202C18.4353 15.3033 18.3666 15.201 18.2682 15.1356Z"
      fill="white"
    />
    <path
      d="M8.37952 18.4364C8.21864 18.3293 8.00264 18.3748 7.89704 18.538C7.79144 18.7012 7.83622 18.9204 7.99707 19.0275C8.15792 19.1347 8.37394 19.0893 8.47959 18.9261C8.53035 18.8477 8.54833 18.7521 8.52956 18.6602C8.51079 18.5684 8.45682 18.4879 8.37952 18.4364Z"
      fill="white"
    />
    <path
      d="M15.6828 18.3994C15.557 18.4637 15.4817 18.5987 15.4922 18.7413C15.5026 18.8839 15.5967 19.0061 15.7306 19.0509C15.8645 19.0956 16.0117 19.0542 16.1037 18.9458C16.1957 18.8374 16.2143 18.6835 16.1508 18.5558C16.1093 18.472 16.0366 18.4084 15.9488 18.3791C15.861 18.3497 15.7653 18.357 15.6828 18.3994Z"
      fill="white"
    />
    <path
      d="M11.6355 14.8316C11.9058 14.4131 11.8615 13.8619 11.5279 13.4934C11.1944 13.1249 10.6568 13.0332 10.2228 13.2709C9.78889 13.5085 9.56943 14.0148 9.69005 14.4999C9.81068 14.9851 10.2406 15.3251 10.7337 15.3255C11.0974 15.3268 11.437 15.1408 11.6355 14.8316Z"
      fill="white"
    />
    <path
      d="M9.48299 16.509C9.23439 16.344 8.90104 16.4147 8.73833 16.6669C8.57562 16.919 8.64514 17.2573 8.89363 17.4224C9.14211 17.5876 9.47551 17.5172 9.63838 17.2651C9.80107 17.0128 9.73152 16.6744 9.48299 16.509Z"
      fill="white"
    />
    <path
      d="M11.9948 18.6013C11.7504 18.5872 11.541 18.7767 11.5271 19.0247C11.5132 19.2726 11.6999 19.4851 11.9443 19.4993C12.1886 19.5135 12.3981 19.324 12.4121 19.0761C12.4189 18.957 12.3787 18.84 12.3004 18.751C12.2221 18.6619 12.1122 18.6081 11.9948 18.6013Z"
      fill="white"
    />
    <path
      d="M11.9994 15.7717C11.7435 15.7559 11.5034 15.8982 11.3914 16.1322C11.2793 16.3662 11.3173 16.6456 11.4877 16.8401C11.658 17.0345 11.9271 17.1055 12.1692 17.02C12.4114 16.9345 12.5788 16.7093 12.5934 16.4495C12.6134 16.0961 12.3477 15.7928 11.9994 15.7717Z"
      fill="white"
    />
    <path
      d="M14.5898 16.4658C14.3955 16.5659 14.2797 16.7749 14.2965 16.9954C14.3134 17.2159 14.4596 17.4043 14.6668 17.4727C14.8741 17.5412 15.1016 17.4761 15.2431 17.308C15.3846 17.1398 15.4123 16.9017 15.3132 16.7047C15.2486 16.5756 15.1361 16.4778 15.0004 16.433C14.8647 16.3882 14.7169 16.4 14.5898 16.4658Z"
      fill="white"
    />
  </svg>
);

export default CardanoIcon;
