import React from "react";
import scss from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  classname?: string;
  price: string;
  subPrice: string;
  currencyName: string;
}

const Price = ({ classname, subPrice, price, currencyName }: IProps) => {
  return (
    <div className={cn(scss.container, classname)}>
      <div className={scss.price}>{price}</div>
      <div className={scss.subPrice}>{`≈${subPrice} ${currencyName}`}</div>
    </div>
  );
};

export default Price;
