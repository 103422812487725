// import {useEffect} from "react";
import scss from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import { widget, ChartingLibraryWidgetOptions, /*LanguageCode,*/ IChartingLibraryWidget /*ResolutionString*/ } from "../../../../../charting_library";
import Datafeed from "./datafeed";
import cn from "classnames";
// symbol: 'AAPL',
//     interval: 'D' as ResolutionString,
//     datafeedUrl: 'https://demo_feed.tradingview.com',
//     libraryPath: '/charting_library/',
//     chartsStorageUrl: 'https://saveload.tradingview.com',
//     chartsStorageApiVersion: '1.1',
//     clientId: 'tradingview.com',
//     userId: 'public_user_id',
//     fullscreen: false,
//     autosize: true,
//     studiesOverrides: {},

interface IProps {
  classname?: string;
}

const TradingView = ({ classname }: IProps) => {
  const tvRef = useRef<HTMLDivElement>(null);
  const widgetRef = useRef<IChartingLibraryWidget>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!tvRef.current) {
      return;
    }
    // @ts-ignore
    const widgetOptions: ChartingLibraryWidgetOptions = {
      // symbol: "AAPL",
      // @ts-ignore
      datafeed: Datafeed,
      // // BEWARE: no trailing slash is expected in feed URL
      // // tslint:disable-next-line:no-any
      // // datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed("https://demo-feed-data.tradingview.com"),
      interval: "1D" as ChartingLibraryWidgetOptions["interval"],
      container: tvRef.current,
      library_path: "/static/chart/charting_library/",
      // locale: "en",
      theme: "Dark",
      // disabled_features: ["use_localstorage_for_settings"],
      // enabled_features: ["study_templates"],
      // // charts_storage_url: "https://saveload.tradingview.com",
      // charts_storage_api_version: "1.1",
      // client_id: "tradingview.com",
      // user_id: "public_user_id",
      fullscreen: false,
      autosize: true,
      // studies_overrides: {}
      symbol: "Bitfinex:BTC/USD" // default symbol
    };

    widgetRef.current = new widget(widgetOptions);

    widgetRef.current.onChartReady(() => {
      setReady(true);
    });
  }, []);
  // <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
  // useEffect(() => {
  //     // @ts-ignore
  //     new TradingView.widget(
  //         {
  //             "autosize": true,
  //             "symbol": "BINANCE:BTCUSDT",
  //             "timezone": "Etc/UTC",
  //             "theme": "dark",
  //             "style": "8",
  //             "locale": "en",
  //             "toolbar_bg": "#f1f3f6",
  //             "enable_publishing": false,
  //             "withdateranges": true,
  //             "range": "YTD",
  //             "hide_side_toolbar": false,
  //             "allow_symbol_change": false,
  //             "details": false,
  //             "hotlist": false,
  //             "calendar": false,
  //             "show_popup_button": false,
  //             "popup_width": "1000",
  //             "popup_height": "650",
  //             "container_id": "tradingview",
  //             "studies": [
  //                 "ROC@tv-basicstudies",
  //                 "StochasticRSI@tv-basicstudies",
  //                 "MASimple@tv-basicstudies"
  //             ],
  //             datafeed: {
  //                 onChartReady: () => {
  //                     console.log("start")
  //                 }
  //             }
  //         }
  //     );
  // }, [])

  return (
    <div className={cn(scss.container, classname)}>
      {!ready && <div className={scss.loader}>Loading</div>}
      <div ref={tvRef} className={cn(scss.chart, { [scss.show]: ready })} />
    </div>
  );
};

export default TradingView;
