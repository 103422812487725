import React from "react";
import scss from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  classname?: string;
}

const Assets = ({ classname }: IProps) => {
  return <div className={cn(scss.container, classname)}>Assets</div>;
};

export default Assets;
