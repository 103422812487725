import React from "react";
import scss from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  classname?: string;
}

const OrderBook = ({ classname }: IProps) => {
  return <div className={cn(scss.container, classname)}>Order Book</div>;
};

export default OrderBook;
