import { ModalLayout, ModalLayoutHeader } from "@components/shared/ModalLayout/ModalLayout";
import MenuItem from "../../../../../../../../../../shared/Menu/MenuItem";
import { useTranslation } from "react-i18next";
import ModalStore from "@store/ModalStore";

const LangModal = () => {
  const { i18n } = useTranslation();
  return (
    <ModalLayout>
      <ModalLayoutHeader />
      <MenuItem
        large
        title="English"
        active={i18n.language === "en-US"}
        onClick={() => {
          ModalStore.closeModal();
          i18n.changeLanguage("en-US");
        }}
      />
      <MenuItem
        large
        title="Español"
        active={i18n.language === "es-ES"}
        onClick={() => {
          ModalStore.closeModal();
          i18n.changeLanguage("es-ES");
        }}
      />
    </ModalLayout>
  );
};

export default LangModal;
