import React, { useState } from "react";
import scss from "./styles.module.scss";
import SpotTabItem from "@components/Pages/TradePage/components/Spot/components/SpotMainSection/SpotTabItem";
import { SpotTabs } from "@components/Pages/TradePage/components/Spot/Spot";
import Slider from "@components/Pages/TradePage/components/Spot/components/SpotSlider/Slider";
import Input from "@components/shared/Input/Input";
import BalanceInfo from "@components/Pages/TradePage/components/Spot/components/SpotMainSection/BalanceInfo";

interface IProps {
  activeTab: SpotTabs;
  changeTabHandler: (tab: SpotTabs) => void;
}

const SpotMainSection = ({ changeTabHandler, activeTab }: IProps) => {
  const [sliderValue, setSliderValue] = useState<number>(0);
  const sliderInfo = {
    min: 0,
    max: 100
  };

  return (
    <div className={scss.mainSection}>
      <div className={scss.tabsWrapper}>
        <SpotTabItem isActive={activeTab === SpotTabs.LIMIT} changeTabHandler={changeTabHandler} title={SpotTabs.LIMIT} />
        <SpotTabItem isActive={activeTab === SpotTabs.MARKET} changeTabHandler={changeTabHandler} title={SpotTabs.MARKET} />
        <SpotTabItem isActive={activeTab === SpotTabs.TPSL} changeTabHandler={changeTabHandler} title={SpotTabs.TPSL} withDropdown />
      </div>
      <BalanceInfo />
      <Input type={"text"} />
      <Input type="text" />
      <Slider min={sliderInfo.min} max={sliderInfo.max} marks={[0, 25, 50, 75, 100]} defaultStartValue={0} setSliderValue={setSliderValue} sliderValue={sliderValue} />
      <div className={scss.sliderMinMaxInfo}>
        <div>{`${sliderInfo.min} %`}</div>
        <div>{`${sliderInfo.max} %`}</div>
      </div>
      <Input type="text" />
    </div>
  );
};

export default SpotMainSection;
