import React from "react";
import scss from "./styles.module.scss";
import cn from "classnames";
import Spot from "@components/Pages/TradePage/components/Spot/Spot";
import Assets from "@components/Pages/TradePage/components/Assets/Assets";

interface IProps {
  classname?: string;
}

const SpotAndAssets = ({ classname }: IProps) => {
  return (
    <div className={cn(scss.container, classname)}>
      <Spot classname={scss.spotContainer} />
      <Assets classname={scss.assetsContainer} />
    </div>
  );
};

export default SpotAndAssets;
