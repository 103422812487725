import React, { useState } from "react";
import scss from "./styles.module.scss";
import cn from "classnames";
import Button from "@components/shared/Button";
import SpotHeader from "@components/Pages/TradePage/components/Spot/components/SpotHeader/SpotHeader";
import SpotMainSection from "@components/Pages/TradePage/components/Spot/components/SpotMainSection/SpotMainSection";

export enum SpotTabs {
  LIMIT = "Limit",
  MARKET = "Market",
  TPSL = "TP/SL"
}

type pageStatus = "buy" | "sell";

interface IProps {
  classname?: string;
}

const Spot = ({ classname }: IProps) => {
  const [pageStatus, setPageStatus] = useState<pageStatus>("buy");
  const [activeTab, setActiveTab] = useState<SpotTabs>(SpotTabs.LIMIT);

  const changeStatusHandler = (status: pageStatus) => () => {
    if (pageStatus !== status) setPageStatus(status);
  };

  return (
    <div className={cn(scss.container, classname)}>
      <SpotHeader />
      <div className={scss.buySellButtonsWrapper}>
        <Button onClick={changeStatusHandler("buy")} fullWidth variant={pageStatus === "buy" ? "success" : "secondary"}>
          Buy
        </Button>
        <Button onClick={changeStatusHandler("sell")} fullWidth variant={pageStatus === "sell" ? "danger" : "secondary"}>
          Sell
        </Button>
      </div>
      <SpotMainSection changeTabHandler={setActiveTab} activeTab={activeTab} />
      <Button className={scss.buyOrSellButton} variant={pageStatus === "buy" ? "success" : "danger"} size={"large"} fullWidth>
        {pageStatus === "buy" ? "Buy" : "Sell"} BTC
      </Button>
    </div>
  );
};

export default Spot;
