import { SVGProps } from "react";

const LitecoinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="12" fill="#A5A8A9" />
    <path
      d="M12.1947 12.3947L10.9465 16.6066H17.6228C17.7305 16.6069 17.8317 16.6577 17.8962 16.7439C17.9607 16.8301 17.981 16.9416 17.9509 17.0449L17.3703 19.0449C17.3134 19.2412 17.1335 19.3762 16.9291 19.3758H6.7317L8.45594 13.5529L6.54883 14.1335L6.96973 12.7721L8.87974 12.1915L11.3006 4.01155C11.3553 3.81456 11.5345 3.6781 11.739 3.67774H14.3224C14.4299 3.67735 14.5313 3.72744 14.5963 3.813C14.6614 3.89857 14.6825 4.0097 14.6533 4.11315L12.6214 11.0304L14.5285 10.4499L14.1192 11.8345L12.1947 12.3947Z"
      fill="white"
    />
  </svg>
);

export default LitecoinIcon;
