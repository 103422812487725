import { SVGProps } from "react";

const EthereumIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="12" fill="#EDEFF1" />
    <path d="M7 12.1484L11.9985 15.0512V9.91627V4L7 12.1484Z" fill="#898989" />
    <path d="M11.9985 4V9.91627V15.0512L16.997 12.1484L11.9985 4Z" fill="#323232" />
    <path d="M12 9.91699L16.997 12.1484L12 15.0505V9.91699Z" fill="#141414" />
    <path d="M11.9985 9.9165L12 9.917V15.05L11.9985 15.0515L7 12.1486L11.9985 9.9165Z" fill="#373737" />
    <path d="M7 13.0796L11.9985 19.9999V15.9809L7 13.0796Z" fill="#898989" />
    <path d="M11.9985 15.9809V19.9999L17 13.0796L11.9985 15.9809Z" fill="#323232" />
  </svg>
);

export default EthereumIcon;
