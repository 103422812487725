import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import scss from "./styles.module.scss";
import classNames from "classnames";

type Props = {
  onClick?: () => void;
  children: ReactNode;
  variant: "primary" | "secondary" | "outlined" | "success" | "danger" | "custom";
  className?: string;
  size: "xl" | "large" | "medium" | "small";
  fullWidth?: boolean;
  iconBefore?: ReactNode;
} & (
  | {
      type?: "button";
      disabled?: boolean;
    }
  | {
      type?: "link";
      to: string;
    }
);

const Button = (props: Props) =>
  props.type === "link" ? (
    <NavLink className={classNames(props.className, scss.button, scss[props.variant], scss[props.size], { [scss.fullWidth]: props.fullWidth })} to={props.to}>
      {props.children}
    </NavLink>
  ) : props.type === "button" ? (
    <button
      className={classNames(props.className, scss.button, scss[props.variant], scss[props.size], { [scss.fullWidth]: props.fullWidth })}
      disabled={props.disabled}
      onClick={!props.disabled ? props.onClick : undefined}
    >
      {props.iconBefore && <div className={scss.iconWrapper}>{props.iconBefore}</div>}

      {props.children}
    </button>
  ) : null;

Button.defaultProps = {
  type: "button",
  variant: "primary",
  size: "medium"
};

export default Button;
