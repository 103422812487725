import Button from "@components/shared/Button";
import scss from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface Props {
  className?: string;
}

const HeaderAuth = ({ className }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(scss.auth, className)}>
      <Button size={"medium"} type="link" to="/log-in" variant="secondary" className={scss.login}>
        {t("login")}
      </Button>
      <Button size={"medium"} type="link" to="/sign-up"> {t("signup")}</Button>
    </div>
  );
};

export default HeaderAuth;
