import { SVGProps } from "react";
const Search = (props: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16675 9.28797C4.16638 6.84533 5.89154 4.74258 8.28718 4.26571C10.6828 3.78884 13.0817 5.07066 14.0168 7.32724C14.9518 9.58383 14.1626 12.1867 12.1318 13.544C10.1009 14.9013 7.39414 14.635 5.66675 12.908C4.70644 11.948 4.16686 10.6458 4.16675 9.28797Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.9077 12.9088L15.8335 15.8346" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default Search;
