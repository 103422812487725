import { SVGProps } from "react";

const Warning = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_658_7975)">
      <circle cx="8" cy="8" r="8" fill="#FFB11A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.36841 11.3684C7.36841 11.0196 7.65119 10.7368 7.99999 10.7368H8.00841C8.35721 10.7368 8.63999 11.0196 8.63999 11.3684V11.3768C8.63999 11.7256 8.35721 12.0084 8.00841 12.0084H7.99999C7.65119 12.0084 7.36841 11.7256 7.36841 11.3768V11.3684Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99991 8.42107C7.7674 8.42107 7.57886 8.23252 7.57886 8.00001V4.63159C7.57886 4.39905 7.7674 4.21054 7.99991 4.21054C8.23242 4.21054 8.42096 4.39905 8.42096 4.63159V8.00001C8.42096 8.23252 8.23242 8.42107 7.99991 8.42107Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_658_7975">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Warning;
