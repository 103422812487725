import MainLayout from "@components/Layouts/MainLayout";
import MainHeader from "@components/Layouts/MainHeader";
import TradingView from "./components/TradingView/TradingView";
import scss from "./styles.module.scss";
import resolutionStore from "@store/ResolutionStore";
import TickerHeader from "@components/Pages/TradePage/components/TickerHeader/TickerHeader";
import OrderBook from "@components/Pages/TradePage/components/OrderBook/OrderBook";
import History from "@components/Pages/TradePage/components/History/History";
import SpotAndAssets from "@components/Pages/TradePage/components/SpotAndAssets/SpotAndAssets";
import { observer } from "mobx-react-lite";

const TradePage = () => {
  const resolution = resolutionStore.resolution;
  const tabRes = resolution.l || resolution.m || resolution.s;

  return (
    <MainLayout>
      <MainHeader />
      <div className={scss.tradePageContainer}>
        <TickerHeader resolution={resolution} classname={scss.tickerHeaderGridItem} />
        <TradingView classname={scss.chartGridItem} />
        {!tabRes && <OrderBook classname={scss.OrderBookGridItem} />}
        {!tabRes && <SpotAndAssets classname={scss.spotAndAssetsGridItem} />}
        <History classname={scss.historyGridItem} />
      </div>
    </MainLayout>
  );
};

export default observer(TradePage);
