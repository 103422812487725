import React, { HTMLProps } from "react";
import scss from "../styles.module.scss";
import cn from "classnames";

interface IProps {
  props: HTMLProps<HTMLSpanElement>;
  onClick?: (value: number) => void;
  value: number;
  classNames?: {
    filledMark?: string;
    mark?: string;
  };
}

const SliderMark = ({ props, onClick, value, classNames }: IProps) => {
  const isFilled = typeof props.key === "number" && props.key >= 0 && value >= props.key;
  const markClickHandler = () => {
    if (onClick) onClick(props.key as number);
  };

  return <span {...props} onClick={markClickHandler} className={cn(scss.sliderMark, classNames?.mark, { [classNames?.filledMark || scss.filled]: isFilled })} />;
};

export default SliderMark;
