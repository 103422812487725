import { observer } from "mobx-react-lite";
import scss from "./styles.module.scss";
import CloseIcon from "@icons/CloseIcon";
import Icon from "@components/shared/Icon";
import RightMenuStore from "@store/RightMenuStore";
import ResolutionStore from "@store/ResolutionStore";
import MobileLang from "@components/Layouts/MainHeader/components/HeaderMenu/components/BurgerMenu/components/MobileLang/MobileLang";
import classNames from "classnames";
import MobileAuth from "../MobileAuth/MobileAuth";
import HeaderSearch from "@components/Layouts/MainHeader/components/HeaderSearch";
import MobileMenu from "@components/Layouts/MainHeader/components/HeaderMenu/components/BurgerMenu/components/MobileMenu/MobileMenu";

const MobilePanel = observer(() => {
  const { isMobile, isTablet } = ResolutionStore.resolutionState;

  return (
    <div className={scss.panel}>
      <div className={scss.header}>
        <Icon className={scss.close} onClick={RightMenuStore.closeMenu}>
          <CloseIcon />
        </Icon>
      </div>
      <div className={classNames(scss.content, "scroll")}>
        <MobileAuth />
        {(isMobile || isTablet) && <HeaderSearch className={scss.search} inputClassName={scss.searchInput} />}
        {isMobile && <MobileMenu />}
      </div>
      <div className={scss.footer}>
        <MobileLang />
      </div>
    </div>
  );
});

export default MobilePanel;
