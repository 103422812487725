import React, { useCallback, useState } from "react";
import scss from "./styles.module.scss";
import cn from "classnames";
import BitcoinIcon from "@icons/crypto/BitcoinIcon";
import EthereumIcon from "@icons/crypto/EthereumIcon";
import CurrencyInfo from "@components/Pages/TradePage/components/TickerHeader/components/CurrencyInfo/CurrencyInfo";
import TradeHeaderActionsPanel from "@components/Pages/TradePage/components/TickerHeader/components/TradeHeaderActionsPanel/TradeHeaderActionsPanel";
import LastDayInfo from "@components/Pages/TradePage/components/TickerHeader/components/LastDayInfo/LastDayInfo";
import Price from "@components/Pages/TradePage/components/TickerHeader/components/Price/Price";
import { Resolution } from "@store/ResolutionStore";

interface IProps {
  classname?: string;
  resolution: Resolution;
}

export interface ICurrencyInfo {
  cryptoTitle: string;
  cryptoCurrency: string;
  monetaryCurrency: string;
  cryptoLogo: JSX.Element;
}

const dayInfo = [
  {
    key: "24H Change",
    value: "+3.11%"
  },
  {
    key: "24H High",
    value: "19,540.51"
  },
  {
    key: "24H Low",
    value: "18,654.22"
  },
  {
    key: "24H Turnover (USDT)",
    value: "566 780 988.52"
  },
  {
    key: "24H Volume (BTC)",
    value: "25 418.41"
  },
  {
    key: "Daily Interest Rate (BTC/USDT)",
    value: "0.005605% | 0.02%"
  }
];

const currencyInfo = {
  BTC: {
    cryptoTitle: "Bitcoin",
    cryptoCurrency: "BTC",
    monetaryCurrency: "USDT",
    cryptoLogo: <BitcoinIcon width={32} height={32} />
  },
  ETH: {
    cryptoTitle: "Ethereum",
    cryptoCurrency: "ETH",
    monetaryCurrency: "USDT",
    cryptoLogo: <EthereumIcon width={32} height={32} />
  }
};

const TickerHeader = ({ classname, resolution }: IProps) => {
  const [currency, setCurrency] = useState<boolean>(true);
  const isMobile = resolution.m || resolution.s;

  const changeCurrency = useCallback(() => {
    setCurrency((prev) => !prev);
  }, []);

  return (
    <div className={cn(scss.container, classname)}>
      <CurrencyInfo classname={scss.currencyInfo} currencyInfo={currency ? currencyInfo.BTC : currencyInfo.ETH} changeCurrencyHandler={changeCurrency} />
      <Price price={"19 540.51"} subPrice={"19 540.51"} currencyName={"USD"} classname={scss.priceGridItem} />
      <LastDayInfo dayInfo={dayInfo} classname={scss.dayInfoGridItem} />
      {!isMobile && <TradeHeaderActionsPanel classname={scss.actionsPanelGridItem} />}
    </div>
  );
};

export default TickerHeader;
