import React from "react";
import scss from "./styles.module.scss";
import Icon from "@components/shared/Icon";
import BookOpen from "@icons/BookOpen";
import Copy from "@icons/Copy";
import SettingsBasic from "@icons/SettingsBasic";
import cn from "classnames";

interface IProps {
  classname?: string;
}

const TradeHeaderActionsPanel = ({ classname }: IProps) => {
  return (
    <div className={cn(scss.container, classname)}>
      <Icon className={scss.copyIcon}>
        <Copy />
        <div className={scss.copyIconTitle}>Copy Trading</div>
      </Icon>
      <div className={scss.separator} />
      <Icon>
        <BookOpen />
      </Icon>
      <Icon>
        <SettingsBasic />
      </Icon>
    </div>
  );
};

export default TradeHeaderActionsPanel;
