import scss from "./styles.module.scss";
import authScss from "../styles.module.scss";
import MainLayout from "@components/Layouts/MainLayout";
import MainHeader from "@components/Layouts/MainHeader";
import AdvertisingImage from "@images/auth-advertising.png";
import Button from "@components/shared/Button";
import Tabs from "@components/shared/Tabs";
import Input from "@components/shared/Input/Input";
import NumberInput from "@components/shared/Input/NumberInput";
import Checkbox from "@components/shared/Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import ContinueWithApple from "@icons/ContinueWithApple";
import ContinueWithGoogle from "@icons/ContinueWithGoogle";
import classNames from "classnames";

const SignUp = () => {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <MainHeader />
      <div className={authScss.whitePage}>
        <div className={authScss.container}>
          <div className={authScss.col}>
            <div className={authScss.paper}>
              <h3 className={authScss.h3}>{t("createAccount")}</h3>
              <div className={authScss.flexRow}>
                <p className={authScss.question}>{t("alreadyHaveAccount")}</p>
                <Button type="link" to="/log-in" className={authScss.authButton}>
                  {t("login")}
                </Button>
              </div>

              <Tabs
                noScroll
                variant="light"
                tabs={[
                  {
                    label: t("email"),
                    content: () => (
                      <div className={authScss.inputWrapper}>
                        <Input label={t("email")} type="email" placeholder={t("emailPlaceholder")} />
                        <Input label={t("password")} type="password" placeholder="••••••••" validate={(value) => value.length === 8} />
                      </div>
                    )
                  },
                  {
                    label: t("mobile"),
                    content: () => <NumberInput label={t("mobile")} />
                  }
                ]}
              />

              <p className={scss.promoOrReferal}>{t("promoOrReferal")}</p>

              <Button size="xl">{t("createAccount")}</Button>

              <Checkbox
                className={scss.checkbox}
                label={
                  <span className={scss.byClicking}>
                    {t("byClicking")} “{t("createAccount")}”, {t("youAgreeTo")} <b>{t("termsOfService")}</b> {t("and")} <b>{t("privacyPolicy")}</b>
                  </span>
                }
              />

              <div className={authScss.divider}>
                <p>{t("or")}</p>
              </div>

              <Button size="xl" className={classNames(authScss.authButton, authScss.continueWithButton)} iconBefore={<ContinueWithGoogle />}>
                {t("continueWithGoogle")}
              </Button>
              <Button size="xl" className={classNames(authScss.authButton, authScss.continueWithButton)} iconBefore={<ContinueWithApple />}>
                {t("continueWithApple")}
              </Button>
            </div>
          </div>

          <div className={authScss.col}>
            <div className={authScss.paper}>
              <h3 className={authScss.advertizing}>{t("authAdvertising")}</h3>
              <p className={authScss.subtitle}>{t("authAdvertisingSubtitle")}</p>
              <img src={AdvertisingImage} alt={t("authAdvertising")} />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SignUp;
