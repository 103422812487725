import { ReactNode, useState } from "react";
import scss from "./styles.module.scss";
import classNames from "classnames";
import CustomPhoneCountrySelect from "./components/CustomPhoneCountrySelect/CustomPhoneCountrySelect";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import useGeoLocation from "@hooks/useGeoLocations";
import { Country } from "react-phone-number-input/input";

type Props = {
  label?: string | ReactNode;
  onChange: (value?: string) => void;
};

const NumberInput = ({ label, onChange, }: Props) => {
  const [errorMessage] = useState<string>("");
  const [isFocused] = useState<boolean>(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState<Country>("US");

  useGeoLocation(setSelectedCountryCode);

  return (
    <div className={classNames(scss.container, { [scss.error]: errorMessage, [scss.focus]: isFocused })}>
      <label className={scss.label}>{label}</label>
      <div className={scss.inputWrapper}>
        <PhoneInput
          className={scss.inputWrapper}
          countrySelectComponent={CustomPhoneCountrySelect}
          numberInputProps={{
            className: scss.input
          }}
          flags={flags}
          onChange={(value) => {
            onChange(value)
          }}
          addInternationalOption={false}
          autoComplete='tel'
          defaultCountry={selectedCountryCode}
        />
      </div>
      <span className={scss.errorMessage}>{errorMessage}</span>
    </div>
  );
};

NumberInput.defaultProps = {
  onChange: () => {},
};

export default NumberInput;
