import { SVGProps } from "react";

const SolanaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="12" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0717 8.03856C16.9947 8.0726 16.9117 8.09023 16.8277 8.0903H4.30689C4.24717 8.09027 4.18876 8.0726 4.1388 8.03943C4.08885 8.00628 4.04952 7.95908 4.02563 7.90362C4.00175 7.84816 3.99434 7.78685 4.00432 7.72719C4.01429 7.66753 4.04122 7.61209 4.08181 7.5677L6.72013 4.69793C6.77726 4.6356 6.84644 4.58586 6.92339 4.55181C7.00031 4.51777 7.08335 4.50016 7.1673 4.50007H19.6882C19.7484 4.4988 19.8077 4.51563 19.8585 4.54844C19.9094 4.58124 19.9496 4.62855 19.9739 4.68441C19.9983 4.74028 20.0058 4.8022 19.9955 4.86239C19.9852 4.92257 19.9576 4.97834 19.9162 5.02267L17.2749 7.89244C17.2178 7.95478 17.1486 8.00453 17.0717 8.03856ZM17.2749 10.152C17.2176 10.09 17.1483 10.0404 17.0714 10.0064C16.9946 9.97238 16.9116 9.9546 16.8277 9.95417H4.30689C4.24717 9.9542 4.18876 9.97187 4.1388 10.005C4.08885 10.0382 4.04952 10.0854 4.02563 10.1409C4.00175 10.1963 3.99434 10.2576 4.00432 10.3173C4.01429 10.3769 4.04122 10.4324 4.08181 10.4768L6.72013 13.3481C6.77747 13.4101 6.84671 13.4596 6.92359 13.4937C7.00048 13.5277 7.08341 13.5455 7.1673 13.5459H19.6882C19.7478 13.5456 19.8059 13.5277 19.8557 13.4944C19.9055 13.4612 19.9445 13.4139 19.9682 13.3585C19.9919 13.3031 19.9992 13.2419 19.9891 13.1824C19.9791 13.1229 19.9523 13.0676 19.9117 13.0233L17.2749 10.152ZM19.9162 15.9324L17.2749 18.8021C17.2178 18.8645 17.1486 18.9142 17.0717 18.9483C16.9947 18.9823 16.9117 18.9999 16.8277 19H4.30689C4.24717 19 4.18876 18.9823 4.1388 18.9491C4.08885 18.916 4.04952 18.8688 4.02563 18.8133C4.00175 18.7579 3.99434 18.6965 4.00432 18.6369C4.01429 18.5772 4.04122 18.5218 4.08181 18.4774L6.72013 15.6076C6.77726 15.5453 6.84644 15.4955 6.92339 15.4615C7.00031 15.4275 7.08335 15.4098 7.1673 15.4098H19.6882C19.7484 15.4085 19.8077 15.4253 19.8585 15.4581C19.9094 15.4909 19.9496 15.5382 19.9739 15.5941C19.9983 15.65 20.0058 15.7119 19.9955 15.7721C19.9852 15.8323 19.9576 15.888 19.9162 15.9324Z"
      fill="url(#paint0_linear_208_2997)"
    />
    <defs>
      <linearGradient id="paint0_linear_208_2997" x1="13.6253" y1="25.295" x2="25.2074" y2="9.18455" gradientUnits="userSpaceOnUse">
        <stop offset="0.08" stopColor="#9945FF" />
        <stop offset="0.3" stopColor="#8752F3" />
        <stop offset="0.5" stopColor="#5497D5" />
        <stop offset="0.6" stopColor="#43B4CA" />
        <stop offset="0.72" stopColor="#28E0B9" />
        <stop offset="0.97" stopColor="#19FB9B" />
      </linearGradient>
    </defs>
  </svg>
);
export default SolanaIcon;
