import { ReactNode } from "react";
import scss from "./styles.module.scss";
import classNames from "classnames";

interface Props {
  children: ReactNode;
  size: "small" | "medium" | "large" | "custom";
  enableTopBorderRadius?: boolean;
  className?: string;
}

const Menu = ({ children, enableTopBorderRadius, size, className }: Props) => {
  return <div className={classNames(scss.menu, "scroll", scss[size], className, { [scss.withTopBorder]: enableTopBorderRadius })}>{children}</div>;
};

Menu.defaultProps = {
  enableTopBorderRadius: true,
  size: "small"
};

export default Menu;
