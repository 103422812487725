import React, { HTMLProps } from "react";
import scss from "../styles.module.scss";
import cn from "classnames";

interface IProps {
  props: HTMLProps<HTMLDivElement>;
  state: { index: number; value: number };
  classNames?: {
    filledTrack?: string;
    unfilledTrack?: string;
  };
}

const SliderTrack = ({ props, state, classNames }: IProps) => {
  const isFilled = state.index === 0;
  return (
    <div
      {...props}
      className={cn(scss.track, {
        [classNames?.unfilledTrack || scss.sliderTrack]: !isFilled,
        [classNames?.filledTrack || scss.sliderFilledTrack]: isFilled
      })}
      style={{ width: `${isFilled ? state.value : 100 - state.value}%` }}
    />
  );
};

export default SliderTrack;
