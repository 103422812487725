import { FC, useEffect, useRef, useState } from "react";
import useRefData from "@hooks/useRefData";
import { Menu, MenuItem } from "@components/shared/Menu";
import scss from "./styles.module.scss";
import classNames from "classnames";

export type MenuLayoutItem = {
  title: string;
  description?: string;
  SubMenu?: FC;
};

interface Props {
  items: MenuLayoutItem[];
}

const MenuLayout = ({ items }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const sectionRef = useRef<HTMLDivElement>(null);
  const selectedIndexRef = useRefData(selectedIndex);
  const selectedItem = items[selectedIndex];

  useEffect(() => {
    if (sectionRef.current) {
      const container = sectionRef.current;
      const handler = (event: MouseEvent) => {
        const item = (event.target as HTMLElement).closest("[data-index]") as HTMLDivElement;
        if (!item || !item.dataset.index) return;
        const targetIndex = Number(item.dataset.index);
        if (selectedIndexRef.current !== targetIndex) {
          setSelectedIndex(targetIndex);
        }
      };

      container.addEventListener("mousemove", handler);
      return () => {
        container.removeEventListener("mousemove", handler);
      };
    }
  }, [sectionRef, selectedIndexRef]);

  return (
    <Menu enableTopBorderRadius={false} size="custom" className={scss.menu}>
      <div className={classNames(scss.container, { [scss.wide]: !!selectedItem?.SubMenu })}>
        <div className={scss.mainSection}>
          <div className={scss.inner}>
            <div ref={sectionRef} className={scss.sectionScroll}>
              {items.map(({ title, description, SubMenu }, i) => (
                <MenuItem index={i} active={selectedIndex === i} key={title} title={title} description={description} arrowIcon={!!SubMenu} />
              ))}
            </div>
          </div>
        </div>
        {!!selectedItem?.SubMenu && (
          <div className={scss.section}>
            <selectedItem.SubMenu />
          </div>
        )}
      </div>
    </Menu>
  );
};

export default MenuLayout;
