import { SVGProps } from "react";

const Telegram = (props: SVGProps<SVGSVGElement>) => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.9906 0.333374C13.6943 0.333374 10.472 1.31086 7.73114 3.14221C4.99033 4.97357 2.85412 7.57655 1.59266 10.622C0.331196 13.6674 0.00114045 17.0185 0.644227 20.2515C1.28731 23.4846 2.87466 26.4543 5.20554 28.7852C7.53641 31.116 10.5061 32.7034 13.7391 33.3465C16.9722 33.9896 20.3233 33.6595 23.3687 32.398C26.4141 31.1366 29.0171 29.0004 30.8485 26.2595C32.6798 23.5187 33.6573 20.2964 33.6573 17C33.6575 14.8113 33.2265 12.6439 32.389 10.6218C31.5515 8.59959 30.3238 6.7622 28.7762 5.21452C27.2285 3.66684 25.3911 2.43919 23.3689 1.60168C21.3467 0.764165 19.1794 0.333192 16.9906 0.333374ZM22.2898 25.5875C22.2274 25.743 22.1325 25.8835 22.0114 25.9993C21.8904 26.1152 21.7459 26.2038 21.5878 26.2593C21.4296 26.3148 21.2614 26.3358 21.0945 26.321C20.9276 26.3062 20.7657 26.2558 20.6198 26.1733L16.0954 22.6577L13.1915 25.3367C13.1241 25.3865 13.0453 25.4185 12.9623 25.4299C12.8793 25.4412 12.7948 25.4315 12.7165 25.4016L13.2732 20.4208L13.291 20.435L13.3024 20.3366C13.3024 20.3366 21.444 12.9241 21.7757 12.6083C22.1115 12.2933 22.0007 12.225 22.0007 12.225C22.0198 11.8408 21.3982 12.225 21.3982 12.225L10.6106 19.165L6.11817 17.635C6.11817 17.635 5.42817 17.3875 5.36317 16.8434C5.29483 16.3034 6.14065 16.01 6.14065 16.01L24.0023 8.91419C24.0023 8.91419 25.4706 8.25999 25.4706 9.34337L22.2898 25.5875Z"
      fill="#0088CC"
    />
  </svg>
);

export default Telegram;
