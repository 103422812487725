import { SVGProps } from "react";

const ContinueWithApple = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" width="24" height="24" rx="12" fill="white" />
    <g clip-path="url(#clip0_631_7357)">
      <path
        d="M19.0152 15.4689C18.771 16.0279 18.482 16.5424 18.1471 17.0155C17.6906 17.6604 17.3169 18.1068 17.0289 18.3547C16.5824 18.7616 16.104 18.97 15.5917 18.9818C15.224 18.9818 14.7805 18.8781 14.2642 18.6678C13.7463 18.4584 13.2703 18.3547 12.8351 18.3547C12.3786 18.3547 11.8891 18.4584 11.3654 18.6678C10.841 18.8781 10.4185 18.9878 10.0955 18.9986C9.60429 19.0194 9.11465 18.8051 8.6259 18.3547C8.31395 18.0851 7.92377 17.6229 7.45636 16.9681C6.95485 16.2688 6.54255 15.458 6.21954 14.5336C5.87361 13.5351 5.7002 12.5682 5.7002 11.6321C5.7002 10.5599 5.93401 9.63506 6.40232 8.86007C6.77038 8.23757 7.26002 7.74652 7.87285 7.38604C8.48567 7.02556 9.14783 6.84186 9.86092 6.83011C10.2511 6.83011 10.7628 6.94971 11.3986 7.18476C12.0327 7.42061 12.4398 7.54021 12.6183 7.54021C12.7518 7.54021 13.204 7.40036 13.9707 7.12156C14.6958 6.863 15.3077 6.75594 15.809 6.79811C17.1674 6.90675 18.188 7.4374 18.8667 8.39342C17.6518 9.12288 17.0508 10.1446 17.0628 11.4552C17.0737 12.4762 17.4475 13.3257 18.182 14.0003C18.5149 14.3133 18.8866 14.5553 19.3002 14.7272C19.2105 14.9849 19.1158 15.2318 19.0152 15.4689ZM15.8997 3.32009C15.8997 4.12027 15.6047 4.8674 15.0167 5.55894C14.3071 6.38104 13.4488 6.85608 12.518 6.78112C12.5062 6.68512 12.4993 6.58409 12.4993 6.47792C12.4993 5.70975 12.8368 4.88765 13.436 4.21547C13.7352 3.87514 14.1157 3.59215 14.5772 3.36641C15.0376 3.14404 15.4731 3.02106 15.8828 3C15.8947 3.10697 15.8997 3.21395 15.8997 3.32008V3.32009Z"
        fill="#1E2329"
      />
    </g>
    <defs>
      <clipPath id="clip0_631_7357">
        <rect width="15" height="16" fill="white" transform="translate(5 3)" />
      </clipPath>
    </defs>
  </svg>
);

export default ContinueWithApple;
