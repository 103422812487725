import Input from "../../Input";
import scss from "./styles.module.scss";
import { useMemo, useState } from "react";
import { CountryCode } from "../CustomPhoneCountrySelect/CustomPhoneCountrySelect";
import Search from "@icons/Search";
import { Country } from "react-phone-number-input/input";

type Props = {
  codes: CountryCode[]
  iconComponent: React.ElementType<{ country: string; label: string }>;
  onChange: (value: Country) => void
}

const PhonePopup = ({ codes, onChange, iconComponent: Icon }: Props ) => {
  const [popupSearch, setPopupSearch] = useState<string>("");

  const filteredCodesByInput = useMemo(() => {
    return codes.filter((countryCode) => countryCode.key.includes(popupSearch) || countryCode.name.toLocaleLowerCase().includes(popupSearch) || countryCode.code.includes(popupSearch));
  }, [codes, popupSearch]);

  return (
    <div className={scss.container}>
    <Input type="text" value={popupSearch} onChange={(value) => setPopupSearch(value)} iconBefore={<Search className={scss.searchIcon} />} placeholder="Search..." callOnChangeEachSymbol />

    <div className={scss.scrollableContainer}>
      {filteredCodesByInput.map((item) => {
        return (
          <div className={scss.countryContainer} key={item.code + item.name} onClick={() => {onChange(item.key)}}>
            <div className={scss.countryFlag}>
              <Icon country={item.key} label={item.name} />
            </div>
            <div className={scss.countryCode}>+{item.code}</div>
            <div className={scss.countryName}>{item.name}</div>
          </div>
        );
      })}
    </div>
  </div>
  )
}

export default PhonePopup
