import { memo, SVGProps } from "react";

const ThreeDotsVertical = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.125 13.706C12.998 13.706 13.7057 12.9983 13.7057 12.1252C13.7057 11.2522 12.998 10.5444 12.125 10.5444C11.2519 10.5444 10.5442 11.2522 10.5442 12.1252C10.5442 12.9983 11.2519 13.706 12.125 13.706Z"
        fill="currentColor"
      />
      <path
        d="M12.125 6.50531C12.998 6.50531 13.7057 5.79757 13.7057 4.92453C13.7057 4.05149 12.998 3.34375 12.125 3.34375C11.2519 3.34375 10.5442 4.05149 10.5442 4.92453C10.5442 5.79757 11.2519 6.50531 12.125 6.50531Z"
        fill="currentColor"
      />
      <path
        d="M12.125 20.9067C12.998 20.9067 13.7057 20.1989 13.7057 19.3259C13.7057 18.4529 12.998 17.7451 12.125 17.7451C11.2519 17.7451 10.5442 18.4529 10.5442 19.3259C10.5442 20.1989 11.2519 20.9067 12.125 20.9067Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ThreeDotsVertical);
