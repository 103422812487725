import scss from "./styles.module.scss";
import { observer } from "mobx-react-lite";
import { MouseEvent, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import ModalStore from "@store/ModalStore";

const Modal = observer(() => {
  const state = ModalStore.state;
  const root = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    root.current.id = "right-panel";
    document.body.append(root.current);
  }, []);

  const onOutsideClick = (e: MouseEvent) => {
    if (e.currentTarget === e.target) ModalStore.closeModal();
  };

  return createPortal(
    <div className={classNames(scss.overlay, { [scss.active]: state.opened })} onClick={onOutsideClick}>
      <div className={classNames(scss.modal, { [scss.active]: state.opened })}>{state.content}</div>
    </div>,
    root.current
  );
});

export default Modal;
