import { SVGProps } from "react";

const EyeClosed = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.4023 9.40441C15.7013 9.23182 16.0835 9.33424 16.2561 9.63318L18.0381 12.7197C18.2107 13.0187 18.1083 13.4009 17.8094 13.5735C17.5104 13.7461 17.1282 13.6437 16.9556 13.3447L15.1736 10.2582C15.001 9.95924 15.1034 9.577 15.4023 9.40441Z"
      fill="#B2B4B9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9369 11.0457C12.2769 10.9857 12.601 11.2127 12.661 11.5526L13.2167 14.7045C13.2767 15.0444 13.0497 15.3686 12.7097 15.4285C12.3698 15.4885 12.0456 15.2615 11.9857 14.9216L11.4299 11.7697C11.37 11.4298 11.597 11.1056 11.9369 11.0457Z"
      fill="#B2B4B9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.05615 11.0442C8.39608 11.1041 8.62306 11.4283 8.56312 11.7682L8.00727 14.9206C7.94733 15.2605 7.62317 15.4875 7.28324 15.4276C6.9433 15.3676 6.71632 15.0435 6.77626 14.7035L7.33211 11.5511C7.39205 11.2112 7.71621 10.9842 8.05615 11.0442Z"
      fill="#B2B4B9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.59442 9.40189C4.89335 9.57448 4.99578 9.95673 4.82319 10.2557L3.03258 13.3571C2.85999 13.656 2.47775 13.7584 2.17882 13.5858C1.87988 13.4133 1.77746 13.031 1.95005 12.7321L3.74066 9.63066C3.91325 9.33173 4.29549 9.2293 4.59442 9.40189Z"
      fill="#B2B4B9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.10719 7.7072C2.37569 7.49028 2.76921 7.53209 2.98613 7.8006C4.23071 9.34114 6.47724 11.25 10 11.25C13.5228 11.25 15.7693 9.34116 17.0139 7.80061C17.2308 7.53211 17.6243 7.4903 17.8928 7.70722C18.1613 7.92414 18.2031 8.31765 17.9862 8.58615C16.6038 10.2972 14.0345 12.5 10 12.5C5.96551 12.5 3.39616 10.2972 2.01379 8.58613C1.79687 8.31763 1.83869 7.92412 2.10719 7.7072Z"
      fill="#B2B4B9"
    />
  </svg>
);

export default EyeClosed;
