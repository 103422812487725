import scss from "./styles.module.scss";
import Search from "@icons/Search";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface Props {
  className?: string;
  inputClassName?: string;
}
const HeaderSearch = ({ className, inputClassName }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(scss.search, className)}>
      <Search className={scss.icon} width={20} height={20} />
      <input className={classNames(scss.input, inputClassName)} placeholder={t("headerSearchPlaceholder")} />
    </div>
  );
};

export default HeaderSearch;
