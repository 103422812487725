import React, { HTMLProps } from "react";
import scss from "../styles.module.scss";
import cn from "classnames";

interface IProps {
  props: HTMLProps<HTMLDivElement>;
  className?: string;
}

const SliderThumb = ({ props, className }: IProps) => {
  return <div {...props} className={cn(scss.sliderThumb, className)} />;
};

export default SliderThumb;
