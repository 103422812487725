import React from "react";
import scss from "./styles.module.scss";

interface IProps {
  currency?: string;
  balance?: number;
}

const BalanceInfo = ({ balance, currency = "USDT" }: IProps) => {
  const bal = balance?.toLocaleString();
  return (
    <div className={scss.balanceWrapper}>
      <div className={scss.balancePlaceholder}>Available Balance</div>
      <div>
        {bal || "--"}
        {` ${currency}`}
      </div>
    </div>
  );
};

export default BalanceInfo;
