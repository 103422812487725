enum KeyCode {
    ENTER = 13,
    ALT = 18,
    ESC = 27,
    CTRL = 17,
    BACKSPACE = 8,
    DELETE = 46,
    TAB = 9,
    SPACE = 32,
    PAGE_UP = 33,
    PAGE_DOWN = 34,
    ARROW_UP = 38,
    ARROW_DOWN = 40,
    ARROW_RIGHT = 39,
    ARROW_LEFT = 37,
    L = 76,
    SQUARE_BRACKET = 221,
    Z = 90,
    X = 88,
    C = 67,
    D = 68,
    V = 86,
    B = 66,
    N = 78,
    M = 77,
    S = 83,
    U = 85,
    I = 73,
    F = 70,
    Q = 81,
    K = 75,
    G = 71
}

export default KeyCode;
